<div style="margin: 2rem;">

  <div>
    <h1> OCR Jobs </h1>
  </div>
</div>


<div style="margin: 2rem;">
  
    <mat-form-field>
    <mat-label>Cliente</mat-label>
      <input disabled='true' matInput [(ngModel)]="clienteselected">
  </mat-form-field>
  
  
  <mat-form-field>
    <mat-label>Contrato</mat-label>
        <input disabled='true' matInput [(ngModel)]="nomecontrato">
  </mat-form-field>
  
</div>
  


  <div class="mat-elevation-z8">
    <table [dataSource]="dataSource.data" mat-table class="full-width-table" matSort aria-label="Elements">



      <!-- Id Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef >id</th>
        <td mat-cell *matCellDef="let row">{{row.id}}</td>           
      </ng-container>

      <!-- Id Column -->
      <ng-container matColumnDef="baseAno">
        <th mat-header-cell *matHeaderCellDef >Ano Referência</th>
        <td mat-cell *matCellDef="let row">{{row.baseAno}}</td>
      </ng-container>
      

      <!-- Id Column -->
      <ng-container matColumnDef="baseMes">
        <th mat-header-cell *matHeaderCellDef >Mês Referência</th>
        <td mat-cell *matCellDef="let row">{{row.baseMes}}</td>           
      </ng-container>
      <!-- Id Column -->
      <ng-container matColumnDef="lote">
        <th mat-header-cell *matHeaderCellDef >Lote</th>
        <td mat-cell *matCellDef="let row">{{row.lote}}</td>           
      </ng-container>
            

  
      <!-- Name Column -->
      <ng-container matColumnDef="data">
        <th mat-header-cell *matHeaderCellDef >Data da Execução</th>
        <td mat-cell *matCellDef="let row">{{row.data == null ? "" : row.data.seconds*1000 | date:'dd/MM/yyyy | hh:mm a'}}</td>
      </ng-container>
  
      <!-- Name Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef >Status</th>
        <td mat-cell *matCellDef="let row">{{row.status}}</td>
      </ng-container>

      <ng-container matColumnDef="acao">
        <th mat-header-cell *matHeaderCellDef>Ações</th>
        <td mat-cell *matCellDef="let element" class="action-link"> 
             <button mat-stroked-button matTooltip="jobs" matTooltipClass="tooltip-blue"(click)="openJobs(element)" color="primary"><mat-icon>list</mat-icon></button> &nbsp;
             <button mat-stroked-button matTooltip="exportar" matTooltipClass="tooltip-blue"(click)="exportarLote(element)" color="primary"><mat-icon>list</mat-icon></button> &nbsp;
        </td>
      </ng-container>

      
      
      <button mat-raised-button color="primary">Primary</button>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  
    <mat-paginator #paginator
        [length]="dataSource?.data.length"
        [pageIndex]="0"
        [pageSize]="20"
        [pageSizeOptions]="[20, 40, 80, 100]">
    </mat-paginator>
</div>



<div>
  &nbsp;
</div>


  