// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyCdeuq1VN4YxFzHTBKBl3I6MEsinarQamk",
    authDomain: "rpa-testes.firebaseapp.com",
    databaseURL: "https://rpa-testes.firebaseio.com",
    projectId: "rpa-testes",
    storageBucket: "rpa-testes.appspot.com",
    messagingSenderId: "23779511284",
    appId: "1:23779511284:web:0a4be8456cd447ce9847a1",
  },
  urlstorage:'https://storage.cloud.google.com/rpa-testes.appspot.com',
  ambiente: 'dev',
  //urlCloudFunction:'https://us-central1-rpa-testes.cloudfunctions.net'
  urlCloudFunction:'http://localhost:3003'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
