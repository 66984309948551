import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { FormArray } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { DbserviceService } from '../../../services/dbservice.service';

@Component({
  selector: 'app-clientes-form',
  templateUrl: './clientesform.component.html',
  styleUrls: ['./clientesform.component.scss']
})
export class ClientesFormComponent implements OnInit {
  profileForm = this.fb.group({
    id: [{value: '', disabled: true}],
    cnpj: [''],
    nome: [''],
    razaosocial: ['']
  });




  get aliases() {
    return this.profileForm.get('aliases') as FormArray;
  }

  constructor(private fb: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private DbserviceService: DbserviceService) { }

  ngOnInit(): void {

    const parametros = this.route.snapshot.params;      // recebe os parâmetros da chamada enterior
    this.profileForm.patchValue(this.route.snapshot.params);

  }

  updateCliente(payload,id) {
    console.log('updateCliente',payload,id)
    this.DbserviceService.update_DB('clientes',payload,this.route.snapshot.params.id);
    this.router.navigate(['clientes'])
  }


  onSubmit() {
    // TODO: Use EventEmitter with form value
    console.warn(this.profileForm.value);
  }
}


