<!-- dialog-box.component.html -->
<h1 mat-dialog-title><strong>{{action}} Instalação</strong></h1>
<div mat-dialog-content>
  <mat-form-field *ngIf="action != 'Excluir'; else elseTemplate">
    <input placeholder="Numero da instalação" matInput [(ngModel)]="local_data.instalacao">
  </mat-form-field>
  <ng-template #elseTemplate>
    <div>
      Tem certeza que deseja excluir a instalação <b>{{local_data.instalacao}}</b>?
    </div>
  </ng-template>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="doAction()" mat-flat-button color="warn">{{action}}</button>
  <button mat-button (click)="closeDialog()" mat-flat-button color="primary">Cancel</button>

</div>