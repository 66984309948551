import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import {AngularFireAuth} from '@angular/fire/auth';
import {Router} from '@angular/router';
import { first } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-navegacao',
  templateUrl: './navegacao.component.html',
  styleUrls: ['./navegacao.component.scss']
})
export class NavegacaoComponent {

  public liberado = false;
  public ambiente = environment.ambiente;
  

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(private breakpointObserver: BreakpointObserver, public afAuth: AngularFireAuth, private router: Router) {   
  }
  
  isLoggedIn() {
    console.log('liberado antes', this.liberado);
    return this.afAuth.authState.pipe(first()).toPromise();
    
 }
 
 autorizado() {
    const user =  this.isLoggedIn()
    if (user) {
     console.log('autorizado is true');
     user.finally;
     return true;

    } else {
     console.log('autorizado is false');
     user.finally;
     return false;
   }
 }

 logout() {
  this.afAuth.signOut();
  this.router.navigate(['login']);
}


}
