import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { ContratosDataSource, ContratosItem } from './contratos-datasource';
import { DbserviceService } from '../services/dbservice.service';
import { MatIconModule} from '@angular/material/icon';
import { FormControl } from "@angular/forms";
import { ElementRef} from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { QuickjobComponent } from '../modals/quickjob/quickjob.component';
import { DadosacessoComponent } from '../modals/dadosacesso/dadosacesso.component';
import { ResumoComponent } from '../modals/resumo/resumo.component';
import { InstalacoesModalComponent } from '../modals/instalacoes/instalacoes.component';

import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

@Component({
  selector: 'app-contratos',
  templateUrl: './contratos.component.html',
  styleUrls: ['./contratos.component.scss'],
})
export class ContratosComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<ContratosItem>;
  
  dataSource: ContratosDataSource;
  
  contratos: any;
  contratolista: string[] = ['FPblpv89ZrWuDpS6RfoI'];
  contratoselected = 'FPblpv89ZrWuDpS6RfoI';
  clientelista: string[] = ['Ação Engenharia'];
  clienteselected = 'Ação Engenharia';
  nomecontrato = 'acao-engenharia';
  displayedColumns = ['ativo', 'nomeservico', 'cliente','provedor', 'inicioMes', 'inicioAno', 'download', 'ocr', 'id', 'acao' , ];
  ContratoSelecionado: string;
  isChecked = true;
  city: string;
  name: string;
  food_from_modal: string;


  //select: FormControl = new FormControl(this.instalacaolista);

  constructor(private DbserviceService: DbserviceService, private router: Router, public dialog: MatDialog, private _snackBar: MatSnackBar) { }

  ngOnInit() { 

  
    this.DbserviceService.read_Contratos('/contratos/FPblpv89ZrWuDpS6RfoI/servicos').subscribe(data => {
      this.contratos = data.map(e => {
        return {     
          id:              e.payload.doc.id,
          provedor:        e.payload.doc.get('provedor'),
          contrato:        e.payload.doc.get('contrato'),
          nomeservico:     e.payload.doc.get('nomeservico'),
          cliente:         e.payload.doc.get('cliente'),
          clienteservico:  e.payload.doc.get('clienteservico'),
          cnpj:            e.payload.doc.get('cnpj'),
          download:        e.payload.doc.get('download'),
          ocr:             e.payload.doc.get('ocr'),
          ativo:           e.payload.doc.get('ativo'),
          inicioMes:       e.payload.doc.get('inicioMes'),
          inicioAno:       e.payload.doc.get('inicioAno'),
          origem:          'contratos'
          
        };
      })
      console.log('payload: ' + this.contratos); 
      this.dataSource.data = this.contratos;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.table.dataSource = this.dataSource;
      
      
    });
      this.dataSource = new ContratosDataSource(); 
    
  }

  ngAfterViewInit() {
  }

  onContratoSelecionado() {
    console.log(this.ContratoSelecionado);

    this.DbserviceService.read_Invoices('/contratos/'+this.ContratoSelecionado+'/servicos').subscribe(data => {
      this.contratos = data.map(e => {
        return {     
          id:              e.payload.doc.id,
          provedor:        e.payload.doc.get('provedor'),
          nomeservico:     e.payload.doc.get('nomeservico'),
          contrato:        e.payload.doc.get('contrato'),
          cliente:         e.payload.doc.get('cliente'),
          clienteservico:  e.payload.doc.get('clienteservico'),
          cnpj:            e.payload.doc.get('cnpj'),
          download:        e.payload.doc.get('download'),
          ocr:             e.payload.doc.get('ocr'),
          ativo:            e.payload.doc.get('ativo'),
          inicioMes:       e.payload.doc.get('inicioMes'),
          inicioAno:       e.payload.doc.get('inicioAno'),
          origem:          'contratos'
          
        };
      })
      console.log('payload: ' + this.contratos);
      this.dataSource.data = this.contratos;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.table.dataSource = this.dataSource;
    
    });
      
    this.dataSource = new ContratosDataSource(); 
  
  }
  openServicosDetalhes(row) {
    console.log(row);
    this.router.navigate(['servicedetails', row ]);
  }

  openQuickJob(row): void {
    const dialogRef = this.dialog.open(QuickjobComponent, {
      width: '600px',
      data: row
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if(result!=null){                               // se o job avulso for confirmado, adiciona registro e roteia para tela de jobs

        this.DbserviceService.AddNewJob(result);
        this.openSnackBar('Job Avulso enviado.','Fechar');
        this.router.navigate(['jobs' ]);
      };
    });
  }

  openDadosacesso(row): void {
    const dialogRef = this.dialog.open(DadosacessoComponent, {
      width: '600px',
      data: row
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if(result!=null){                               // se o job avulso for confirmado, adiciona registro e roteia para tela de jobs
        this.DbserviceService.UpdateServico('contratos/'+ result.contrato +'/servicos/'+result.servico, result.atualizacao);
        console.log('contratos/'+ result.contrato +'/servicos/'+result.id);
        this.openSnackBar('Dados atualizados','Fechar');
      //  this.router.navigate(['jobs' ]);
      };
    });
  }

  openResumo(row): void {
    const dialogRef = this.dialog.open(ResumoComponent, {
      width: '600px',
      data: row
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if(result!=null){                               // se o job avulso for confirmado, adiciona registro e roteia para tela de jobs
        this.DbserviceService.UpdateServico('contratos/'+ result.contrato +'/servicos/'+result.servico, result.atualizacao);
        console.log('contratos/'+ result.contrato +'/servicos/'+result.id);
        this.openSnackBar('Dados atualizados','Fechar');
      //  this.router.navigate(['jobs' ]);
      };
    });
  }



/*
  openInstalacoes(row): void {
    const dialogRef = this.dialog.open(InstalacoesModalComponent, {
      width: '600px',
      height: '700px',
      data: row
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if(result!=null){                               // se o job avulso for confirmado, adiciona registro e roteia para tela de jobs
        this.DbserviceService.UpdateServico('contratos/'+ result.contrato +'/servicos/'+result.servico, result.atualizacao);
        console.log('contratos/'+ result.contrato +'/servicos/'+result.id);
        this.openSnackBar('Dados atualizados','Fechar');
      //  this.router.navigate(['jobs' ]);
      };
    });
  }
*/

openInstalacoes(row) {
    console.log(row);
    this.router.navigate(['instalacoes', row ]);
  }

  openDialog(row): void {
    const dialogRef = this.dialog.open(QuickjobComponent, {
      width: '600px',
      data: row
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if(result!=null){                               // se o job avulso for confirmado, adiciona registro e roteia para tela de jobs
        this.DbserviceService.AddNewJob(result);
        this.openSnackBar('Dados atualizados','Fechar');
        this.router.navigate(['jobs' ]);
      };
    });
  }


  openSnackBar(mensagem, saida) {
    this._snackBar.open(mensagem, saida, {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }
}


