<div style="margin: 2rem;">
<mat-form-field>
  <mat-label>Concessionária</mat-label>
  <mat-select [value]="concessionariaselected">
    <mat-option *ngFor="let opcao of concessionarialista" [value]="opcao">
      {{opcao}}
    </mat-option>
  </mat-select>
</mat-form-field>

<mat-form-field>
  <mat-label>Cliente</mat-label>
  <mat-select [value]="clienteselected">
    <mat-option *ngFor="let opcao of clientelista" [value]="opcao">
      {{opcao}}
    </mat-option>
  </mat-select>
</mat-form-field>


<mat-form-field>
  <mat-label>Instalação</mat-label>
  <mat-select [(ngModel)]="InstalacaoSelecionada" (ngModelChange)="onInstalacaoSelecionada()">
    <mat-option *ngFor="let opcao of instalacaolista" [value]="opcao">
      {{opcao}}
    </mat-option>
  </mat-select>
</mat-form-field>

</div>

  

<div class="mat-elevation-z8">
  <table mat-table class="full-width-table" matSort aria-label="Elements">
    <!-- Id Column -->
    <ng-container matColumnDef="cliente">
      <th mat-header-cell *matHeaderCellDef >Cliente</th>
      <td mat-cell *matCellDef="let row">{{row.cliente}}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="instalacao">
      <th mat-header-cell *matHeaderCellDef >Instalação</th>
      <td mat-cell *matCellDef="let row">{{row.instalacao}}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="mesano">
      <th mat-header-cell *matHeaderCellDef>Mês/Ano</th>
      <td mat-cell *matCellDef="let row">{{row.mesano}}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="notafiscal">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Número da Conta</th>
      <td mat-cell *matCellDef="let row">{{row.notafiscal}}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="leitant">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Leitura Anterior</th>
      <td mat-cell *matCellDef="let row">{{row.leitant}}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="leitatu">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Leitura Atual</th>
      <td mat-cell *matCellDef="let row">{{row.leitatu}}</td>
    </ng-container>    

    <!-- Name Column -->
    <ng-container matColumnDef="dataemissao">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Data de Emissão</th>
      <td mat-cell *matCellDef="let row">{{row.dataemissao}}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="datavencimento">
      <th mat-header-cell *matHeaderCellDef >Data de Vencimento</th>
      <td mat-cell *matCellDef="let row">{{row.datavencimento}}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="ilumpublica">
      <th  mat-header-cell *matHeaderCellDef>Iluminação Pública</th>
      <td align="center" mat-cell  *matCellDef="let row">{{row.ilumpublica}}</td>
    </ng-container>


    <!-- Name Column -->
    <ng-container matColumnDef="icms">
      <th  mat-header-cell *matHeaderCellDef>ICMS</th>
      <td align="center" mat-cell  *matCellDef="let row">{{row.icms}}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="valor">
      <th  mat-header-cell *matHeaderCellDef >Valor</th>
      <td align="center" mat-cell *matCellDef="let row">{{row.valor}}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="pdf">
      <th  mat-header-cell *matHeaderCellDef  >Pdf</th>
      <td   mat-cell *matCellDef="let row"><a href={{row.pdf}} target="_blank"><mat-icon>picture_as_pdf</mat-icon></a></td>
      <mat-icon aria-hidden="false" aria-label="Example home icon">home</mat-icon>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="json">
      <th  mat-header-cell *matHeaderCellDef  >Json</th>
      <td   mat-cell *matCellDef="let row"><a href="{{row.json}}" target="_blank"><mat-icon>file_copy</mat-icon></a></td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator #paginator
      [length]="dataSource?.data.length"
      [pageIndex]="0"
      [pageSize]="20"
      [pageSizeOptions]="[20, 40, 80, 100]">
  </mat-paginator>
</div>
