import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { ProvedoresTableDataSource, TabelaItem } from './provedorestable-datasource';
import { DbserviceService } from '../../../services/dbservice.service';
import { MatIconModule} from '@angular/material/icon';
import { FormControl } from "@angular/forms";
import { ElementRef} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-provedorestable',
  templateUrl: './provedorestable.component.html',
  styleUrls: ['./provedorestable.component.scss'],
})
export class ProvedoresTableComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<TabelaItem>;
  dataSource: ProvedoresTableDataSource;
  displayedColumns = ['id', 'nome', 'alias','tipo', 'servico', 'acao'];
  InstalacaoSelecionada: string;
  provedores:any;
  newitem:any;

  
  constructor(private DbserviceService: DbserviceService,
              private router: Router) { }

  ngOnInit() {
      
    this.DbserviceService.read_DB('provedores').subscribe(data => {
      this.provedores = data.map(e => {
        return {     
          id:           e.payload.doc.id,
          alias:        e.payload.doc.data()['alias'],
          servico:      e.payload.doc.data()['servico'],
          nome:         e.payload.doc.data()['nome'],
          tipo:         e.payload.doc.data()['tipo'],
          url:          e.payload.doc.data()['url'],
          acao:         'edit'
          
        };
      })
      console.log('payload: ' + this.provedores);
      this.dataSource.data = this.provedores;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.table.dataSource = this.dataSource;
      
    });
    
    this.dataSource = new ProvedoresTableDataSource(); 
    
  }

  ngAfterViewInit() {
  }

  onInstalacaoSelecionada() {
    console.log(this.InstalacaoSelecionada);

    this.DbserviceService.read_DB('provedores').subscribe(data => {
      this.provedores = data.map(e => {
        return {     
          id:           e.payload.doc.id,
          alias:        e.payload.doc.data()['alias'],
          servico:      e.payload.doc.data()['servico'],
          nome:         e.payload.doc.data()['nome'],
          tipo:         e.payload.doc.data()['tipo'],
          url:          e.payload.doc.data()['url'],
          acao:         'edit'
          
        };
      })
      console.log('payload: ' + this.provedores);
      this.dataSource.data = this.provedores;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.table.dataSource = this.dataSource;
    
    });
      
  this.dataSource = new ProvedoresTableDataSource(); 
  
  }

  openProvedoresForm(row,acao) {
    console.log(row);
    row.acao = acao,
    this.router.navigate(['provedoresForm', row ]);
  }


  newProvedoresForm() {
    
    this.newitem = {
            
      alias:    "",
      servico:  "",
      nome:     "",
      tipo:     "",
      url:      "",
      acao:         'new'
    }
    
    this.router.navigate(['provedoresForm', this.newitem ]);
  }

  
}
