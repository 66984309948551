
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dadosacesso',
  templateUrl: './dadosacesso.component.html',
  styleUrls: ['./dadosacesso.component.scss']
})

export class DadosacessoComponent {

  food: string;
  instalacaolista: string[] = ['0400034096', '0400034312', '0400062090', '0400082490', '0400090735', '0400116823'];
  instalacaoselected: string = '0400034096';
  meslista: string[] = ['01','02','03','04','05','06','07','08','09','10', '11','12']
  messelected: string = '01'
  anolista: string[] = ['2021','2020','2019'];
  anoselected: string = '2021'

  constructor(
    public dialogRef: MatDialogRef<DadosacessoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onClickDadosAcesso(): void {
    this.dialogRef.close({
      servico: this.data.id,
      contrato: this.data.contrato,
      atualizacao: { cnpj: this.data.cnpj, clienteservico: this.data.clienteservico},        

    });
  }


}