<h1 mat-dialog-title>Conexões</h1>

<div mat-dialog-content>
    
    <p>Atualize os dados da conexão:</p>
    
    <div class="row">
            
        <div class="col">
            <mat-form-field>
                <mat-label>Serviço</mat-label>
                <input disabled='true' matInput [(ngModel)]="local_data.nomeservico">
            </mat-form-field>
        </div>

        <div class="col">
            <mat-form-field>
                <mat-label>ID do serviço</mat-label>
                <input disabled='true' matInput [(ngModel)]="local_data.servico">
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <mat-form-field>
                <mat-label>Cliente</mat-label>
                <input disabled='true' matInput [(ngModel)]="local_data.cliente">
            </mat-form-field>
        </div>
    
        <div class="col">
            <mat-form-field>
                <mat-label>Provedor</mat-label>
                <input disabled='true' matInput [(ngModel)]="local_data.provedor">
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <mat-form-field>
                <mat-label>Nome da Conexão</mat-label>
                <input disabled='true' matInput [(ngModel)]="local_data.nomeconexao">
            </mat-form-field>
        </div>
    
        <div class="col">
            <mat-form-field>
                <mat-label>ID da Conexão</mat-label>
                <input disabled='true' matInput [(ngModel)]="local_data.conexaoID">
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <mat-form-field>
                <mat-label>Instalação</mat-label>
                <input disabled='true' matInput [(ngModel)]="local_data.instalacao">
            </mat-form-field>
        </div>
    
        <div class="col">
            <mat-form-field>
                <mat-label>Cliente instalação</mat-label>
                <input disabled='true' matInput [(ngModel)]="local_data.clienteinstalacao">
                
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <mat-form-field>
                <mat-label>Usuário</mat-label>
                <input disabled='true' matInput [(ngModel)]="local_data.usuario">
            </mat-form-field>
        </div>
    
        <div class="col">
            <mat-form-field>
                <mat-label>Senha</mat-label>
                <input disabled='true' [type]="hide ? 'password' : 'text'" matInput [(ngModel)]="local_data.senha">
                <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <mat-form-field>
                <mat-label>Website</mat-label>
                 <input disabled='true' (click)="goToLink(local_data.url)" matInput [(ngModel)]="local_data.url"> 
                 <mat-icon matSuffix  (click)="goToLink(local_data.url)" >{{'link'}}</mat-icon>
                 
            </mat-form-field>
        </div>
            
        <div class="col" *ngIf="local_data.clienteacesso != null">
            <mat-form-field>
                <mat-label>Cliente Acesso</mat-label>
                <input disabled='false'  matInput [(ngModel)]="local_data.clienteacesso">
            
            </mat-form-field>
        </div>

    </div>
   





    <div class="row">
        <div class="col">
            <button mat-button (click)="doAction('Cancelar')" mat-flat-button color="accent">Cancelar</button>
        </div>
        <div class="col">
            <button disabled='true' mat-button (click)="doAction('Atualizar')" mat-flat-button color="primary">Gravar</button>
        </div>


    </div>



</div>

