import { Component } from '@angular/core';
import { map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import * as echarts from 'echarts';
import { DbserviceService } from '../services/dbservice.service';
import { ContratosDataSource, ContratosItem } from '../contratos/contratos-datasource';
import { AngularFirestore } from '@angular/fire/firestore';



@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
  /** Based on the screen size, switch from standard to one column per row */
  cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({ matches }) => {
      if (matches) {
        return [
          { title: 'servico-dia-edp-sp', cols: 2, rows: 2, conteudo: 'Conteudo 1' },
          { title: 'Contas carregadas', cols: 1, rows: 1, conteudo: 'chartOption' },
          { title: 'Leitura de contas pendentes de revisão', cols: 1, rows: 2, conteudo: 'Conteudo 3' },
          { title: 'Robôs em execução diária', cols: 1, rows: 1, conteudo: 'Conteudo 4' }
        ];
      }

      return [
        { title: 'servico-dia-edp-sp', cols: 2, rows: 2, conteudo: 'Conteudo 1' },
        { title: 'servico-dia-edp-sp', cols: 2, rows: 2, conteudo: 'Conteudo 1' },
        { title: 'servico-dia-edp-sp', cols: 2, rows: 2, conteudo: 'Conteudo 1' },
        { title: 'servico-dia-edp-sp', cols: 2, rows: 2, conteudo: 'Conteudo 1' },
        { title: 'servico-dia-edp-sp', cols: 2, rows: 2, conteudo: 'Conteudo 1' },
        { title: 'servico-dia-edp-sp', cols: 2, rows: 2, conteudo: 'Conteudo 1' },
        { title: 'servico-dia-edp-sp', cols: 2, rows: 2, conteudo: 'Conteudo 1' },
        { title: 'servico-dia-edp-sp', cols: 2, rows: 2, conteudo: 'Conteudo 1' },
        { title: 'servico-dia-edp-sp', cols: 2, rows: 2, conteudo: 'Conteudo 1' },
        { title: 'servico-dia-edp-sp', cols: 2, rows: 2, conteudo: 'Conteudo 1' },
        { title: 'servico-dia-edp-sp', cols: 2, rows: 2, conteudo: 'Conteudo 1' },
        { title: 'servico-dia-edp-sp', cols: 2, rows: 2, conteudo: 'Conteudo 1' },
        { title: 'servico-dia-edp-sp', cols: 2, rows: 2, conteudo: 'Conteudo 1' },
        { title: 'servico-dia-edp-sp', cols: 2, rows: 2, conteudo: 'Conteudo 1' },
        { title: 'servico-dia-edp-sp', cols: 2, rows: 2, conteudo: 'Conteudo 1' },
        { title: 'servico-dia-edp-sp', cols: 2, rows: 2, conteudo: 'Conteudo 1' },
        { title: 'servico-dia-edp-sp', cols: 2, rows: 2, conteudo: 'Conteudo 1' },
        { title: 'servico-dia-edp-sp', cols: 2, rows: 2, conteudo: 'Conteudo 1' },
        { title: 'servico-dia-edp-sp', cols: 2, rows: 2, conteudo: 'Conteudo 1' },
        { title: 'servico-dia-edp-sp', cols: 2, rows: 2, conteudo: 'Conteudo 1' },
        { title: 'servico-dia-edp-sp', cols: 2, rows: 2, conteudo: 'Conteudo 1' },
        { title: 'servico-dia-edp-sp', cols: 2, rows: 2, conteudo: 'Conteudo 1' },
        { title: 'servico-dia-edp-sp', cols: 2, rows: 2, conteudo: 'Conteudo 1' },
        { title: 'servico-dia-edp-sp', cols: 2, rows: 2, conteudo: 'Conteudo 1' },
        { title: 'servico-dia-edp-sp', cols: 2, rows: 2, conteudo: 'Conteudo 1' },
        { title: 'servico-dia-edp-sp', cols: 2, rows: 2, conteudo: 'Conteudo 1' },
        { title: 'servico-dia-edp-sp', cols: 2, rows: 2, conteudo: 'Conteudo 1' },
        { title: 'servico-dia-edp-sp', cols: 2, rows: 2, conteudo: 'Conteudo 1' },
        { title: 'servico-dia-edp-sp', cols: 2, rows: 2, conteudo: 'Conteudo 1' },
        { title: 'servico-dia-edp-sp', cols: 2, rows: 2, conteudo: 'Conteudo 1' },
        { title: 'servico-dia-edp-sp', cols: 2, rows: 2, conteudo: 'Conteudo 1' },
        { title: 'servico-dia-edp-sp', cols: 2, rows: 2, conteudo: 'Conteudo 1' },
        { title: 'servico-dia-edp-sp', cols: 2, rows: 2, conteudo: 'Conteudo 1' },
        { title: 'servico-dia-edp-sp', cols: 2, rows: 2, conteudo: 'Conteudo 1' },
        { title: 'servico-dia-edp-sp', cols: 2, rows: 2, conteudo: 'Conteudo 1' },
        { title: 'servico-dia-edp-sp', cols: 2, rows: 2, conteudo: 'Conteudo 1' },
        { title: 'servico-dia-edp-sp', cols: 2, rows: 2, conteudo: 'Conteudo 1' },
        { title: 'servico-dia-edp-sp', cols: 2, rows: 2, conteudo: 'Conteudo 1' },
        { title: 'servico-dia-edp-sp', cols: 2, rows: 2, conteudo: 'Conteudo 1' },
        { title: 'servico-dia-edp-sp', cols: 2, rows: 2, conteudo: 'Conteudo 1' },
        { title: 'servico-dia-edp-sp', cols: 2, rows: 2, conteudo: 'Conteudo 1' },
        { title: 'servico-dia-edp-sp', cols: 2, rows: 2, conteudo: 'Conteudo 1' },
      ];
    })
  );
  options: any;

  controle: any;
  constructor(private breakpointObserver: BreakpointObserver, private DbserviceService: DbserviceService, private firestore: AngularFirestore) {}
  
  dataSource: ContratosDataSource;
  servicoBusca:string;
  saida=[];
  contador= 0;
  contacard=0;
  provedor = "";
  provedorFinalizado = 0;
  provedorIncompleto = 0;
  provedorIniciado = 0;
  provedorPendente = 0;
  provedorTimeout = 0;
  meslista: string[] = ['2021-01','2021-02','2021-03','2021-04','2021-05','2021-06','2021-07','2021-08','2021-09','2021-10', '2021-11','2021-12'];
  messelected: string = '';
  chartDom:any;
  myChart:any;

  controleoptions =[];




  
   async ngOnInit(): Promise<void> {
 

   

    }

    

    
    ngAfterViewInit(): void {
       //this.chartDom = document.getElementById('cards');
       //this.myChart = echarts.init(this.chartDom);
      
    }
    



    async updateGraph(saida) {

      



      
  
           
          await this.firestore.collection('/contratos/FPblpv89ZrWuDpS6RfoI/servicos/').get().toPromise().then(async (querySnapshot) => {

             querySnapshot.forEach(async (servico) => {
                // doc.data() is never undefined for query doc snapshots
                //console.log(servico.id);
                 
                this.servicoBusca = servico.id   
                 
                var docRef = await this.firestore.collection('/contratos/FPblpv89ZrWuDpS6RfoI/servicos/'+ this.servicoBusca  + '/controle/').doc(this.messelected);            
             
                await  docRef.get()
                 .toPromise()
                 .then(async (mesano) => {
                   if (mesano.exists) {

                    saida.push({"servico":servico.id, "cliente":servico.data().cliente, "provedor":servico.data().provedor , "nomeservico":servico.data().nomeservico,"finalizado":mesano.data().finalizado, "incompleto":mesano.data().incompleto, "iniciado":mesano.data().iniciado,"pendente": mesano.data().pendente, "timeout":mesano.data().timeout})
                    //saida[this.contador] = "a"
                    this.contador++
                    //console.log('Nova saida', saida, saida.length,this.contador)  

                   }

                  })

                })

              })
     
              console.log('Processando...')
              await new Promise(f => setTimeout(f, 1000));


              saida.sort(function (a, b) {  // ordena
                if (a.provedor > b.provedor) {
                  return 1;
                }
                if (a.provedor < b.provedor) {
                  return -1;
                }                
                return 0;
              });

              this.saida=[];
              this.provedor = saida[0].provedor;
              this.provedorFinalizado = 0;
              this.provedorIncompleto = 0;
              this.provedorIniciado = 0;
              this.provedorPendente = 0;
              this.provedorTimeout = 0;

              for (var i = 0; i < saida.length; i++) {

                if (i == 0 || saida[i].provedor == saida[i-1].provedor) {

                  this.provedorFinalizado = this.provedorFinalizado + saida[i].finalizado
                  this.provedorIncompleto = this.provedorIncompleto + saida[i].incompleto
                  this.provedorIniciado = this.provedorIniciado + saida[i].Iniciado
                  this.provedorPendente = this.provedorPendente + saida[i].pendente
                  this.provedorTimeout = this.provedorTimeout + saida[i].timeout

                 // console.log(this.provedorFinalizado, this.provedorIncompleto,this.provedorIniciado,this.provedorPendente , this.provedorTimeout)                  
                }
                else {

                  this.controleoptions[this.contacard] =  {
                    title: {
                      text: this.provedor,
 
                    },
                    xAxis: {
                      type: 'category',
                      data: ['Finalizado', 'Incompleto', 'Iniciado', 'Pendente', 'Timeout'],
                    },
                    yAxis: {
                      type: 'value',
                    },
                    color: ['#3398DB'],
                    series: [
                      {
                        data: [this.provedorFinalizado, this.provedorIncompleto,this.provedorIniciado,this.provedorPendente , this.provedorTimeout ],
                        type: 'bar',
                      },
                    ],
                    
                  };
                  this.contacard++
                  console.log(saida)

                  //this.myChart.setOption(this.controleoptions[this.contacard]);
                  

                  this.provedor = saida[i].provedor;
                  this.provedorFinalizado = saida[i].finalizado;
                  this.provedorIncompleto = saida[i].incompleto;
                  this.provedorIniciado =  saida[i].iniciado;
                  this.provedorPendente = saida[i].pendente;
                  this.provedorTimeout = saida[i].timeout;
                

                }

              }

              /*
              this.controleoptions =  {
                xAxis: {
                  type: 'category',
                  data: ['Finalizado', 'Incompleto', 'Iniciado', 'Pendente', 'Timeout'],
                },
                yAxis: {
                  type: 'value',
                },
                color: ['#3398DB'],
                series: [
                  {
                    data: [saida[0].finalizado, saida[0].incompleto, saida[0].iniciado, saida[0].pendente, saida[0].timeout],
                    type: 'bar',
                  },
                ],
                
              };
              console.log(saida)
            */
              



     
           

         // console.log(this.controleoptions)

             


    }
  }



