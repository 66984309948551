import { AfterViewInit, Component, OnInit, ViewChild, OnChanges } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { AngularFireStorage } from '@angular/fire/storage';
//import { JobsDataSource, JobsItem } from './jobs-datasource';
import { DbserviceService } from '../services/dbservice.service';
import { MatIconModule} from '@angular/material/icon';
import { FormControl } from "@angular/forms";
import { ElementRef} from '@angular/core';
import { MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {ThemePalette} from '@angular/material/core';
import { Router } from '@angular/router';
import { ChangeDetectorRef } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatDialog } from '@angular/material/dialog';
import { TentativasComponent } from '../modals/tentativas/tentativas.component';
import { ConexoesModalComponent } from '../modals/conexoes/conexoes.component';
import { AngularFirestore } from '@angular/fire/firestore';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';


@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss'],
})
export class JobsComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<JobsItem>;

  displayedColumns = [ 'nomeservico','cliente','provedor','baseMes', 'baseAno', 'instalacao', 'clienteinstalacao', 'id', 'tentativas', 'status', 'mensagem','feito', 'termino' , 'acao'];
  //displayedColumns = ['position', 'name', 'weight', 'symbol'];
  //dataSource: MatTableDataSource<Element> ;
  dataSource: MatTableDataSource<JobsItem> ;
  
  tamanho = 0;
  //dataSource: JobsDataSource;   
  //dataSource: MatTableDataSource<JobsItem>;
  //jobs: [];
  contratolista: string[] = ['FPblpv89ZrWuDpS6RfoI'];
  contratoselected = 'FPblpv89ZrWuDpS6RfoI';
  clientelista: string[] = ['Ação Engenharia'];
  clienteselected = 'Ação Engenharia';
  nomecontrato = 'acao-engenharia';
  
  ContratoSelecionado: string;
  isChecked = true;
  statuslista: string[] = ['pendente','iniciado','verificar','timeout','suspenso','incompleto','finalizado'];
  servicos: string[] = ['servico-bb-celesc',
                      'servico-bb-cemig',
                      'servico-bb-coelba-b',
                      'servico-bb-copel',
                      'servico-bb-cpfl-leste-paulista-a',
                      'servico-bb-cpfl-leste-paulista-b',
                      'servico-bb-cpfl-paulista-a',
                      'servico-bb-cpfl-paulista-b',
                      'servico-bb-cpfl-piratininga-a',
                      'servico-bb-cpfl-piratininga-b',
                      'servico-bb-cpfl-santa-cruz-a',
                      'servico-bb-cpfl-santa-cruz-b',
                      'servico-bb-cpfl-sul-paulista-a',
                      'servico-bb-elektro',
                      'servico-bb-elektro',
                      'servico-bb-enel-go',
                      'servico-bb-enel-sp',
                      'servico-bb-light',
                      'servico-bb-rge-a',
                      'servico-bb-rge-b',
                      'servico-bb-rge-sul-a',
                      'servico-bb-rge-sul-b',
                      'servico-itau-celesc',
                      'servico-itau-cemig',
                      'servico-itau-copel',
                      'servico-itau-cpfl-paulista-a',
                      'servico-itau-cpfl-paulista-b',
                      'servico-itau-cpfl-piratininga-a',
                      'servico-itau-cpfl-piratininga-b',
                      'servico-itau-cpfl-santa-cruz-a',
                      'servico-itau-cpfl-santa-cruz-b',
                      'servico-itau-elektro',
                      'servico-itau-enel-go',
                      'servico-itau-enel-sp',
                      'servico-itau-light',
                      'servico-itau-rge-a',
                      'servico-itau-rge-b',
                      'servico-itau-rge-sul-a',
                      'servico-itau-rge-sul-b',
                      'servico-itau-sabesp']
  statusselected = '';
  meslista: string[] = ['01','02','03','04','05','06','07','08','09','10', '11','12'];
  messelected: string = '';
  anolista: string[] = ['2026','2025','2024','2023','2022','2021','2020','2019'];
  anoselected: string = '2024';
  retorno:any;
  jobsitem: JobsItem[];  
  sliceinicio = 0;
  slicefim = 20;
  selectedValue: any;
  

  
  //select: FormControl = new FormControl(this.instalacaolista);

  constructor(private DbserviceService: DbserviceService,
              private router: Router,
              private storage: AngularFireStorage,
              private _snackBar: MatSnackBar,
              public dialog: MatDialog,
              private firestore: AngularFirestore) 
  {  this.dataSource = new MatTableDataSource()
   
   
  }

   ngOnInit() {
    this.dataSource.data = JOBS_DATA;  
    
  }

  ngAfterViewInit() {
   this.dataSource.sort = this.sort;
   this.dataSource.paginator = this.paginator;
 
  }

  openServicosDetalhes(row) {
    console.log(row);
    this.router.navigate(['servicedetails', row ]);
  }

  reprocessarJob(row){
    this.DbserviceService.atualizarJob(row.id,'r3pa-job','pendente');
    this.openSnackBar('Job enviado para reprocessamento.','Fechar');
    //this.router.navigate(['jobs' ]);     
    
  }

  pendenteJob(row){
    this.DbserviceService.alteraStatusJob(row.id,'pendente');
    this.openSnackBar('Job enviado para reprocessamento','Fechar');
    //this.router.navigate(['jobs' ]);     
    this.atualizaFiltro()
  }
    

  verificarJob(row){
    this.DbserviceService.alteraStatusJob(row.id,'verificar');
    this.openSnackBar('Job enviado para verificação','Fechar');
    //this.router.navigate(['jobs' ]);     
    this.atualizaFiltro()
    
  }

  suspenderJob(row){
    this.DbserviceService.alteraStatusJob(row.id,'suspenso');
    this.openSnackBar('Job enviado para verificação','Fechar');
    //this.router.navigate(['jobs' ]);     
    this.atualizaFiltro()
    
  }
  
  

  openSnackBar(mensagem, saida) {
    this._snackBar.open(mensagem, saida, {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  openTentativas(row): void {
    const dialogRef = this.dialog.open(TentativasComponent, {
      width: '800px',
      height: '800px',
      data: row
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
    });
  }





  async atualizaFiltro(){
    console.log('antes 3')
        //this.dataSource.data = [];
        const subscription = this.DbserviceService.read_Jobs_whereStatus('/jobs',this.statusselected,this.messelected, this.anoselected).subscribe(data => {
          this.retorno = data.map(e => {
            return {     
              id:              e.payload.doc.id,
              contrato:        e.payload.doc.get('contrato'),
              servico:         e.payload.doc.get('servico'),
              provedor:        e.payload.doc.get('provedor'),
              nomeservico:     e.payload.doc.get('nomeservico'),
              cliente:         e.payload.doc.get('cliente'),
              clienteinstalacao:  e.payload.doc.get('clienteinstalacao'),
              baseMes:         e.payload.doc.get('baseMes'),   
              baseAno:         e.payload.doc.get('baseAno'),   
              robot:           e.payload.doc.get('robot'),
              status:          e.payload.doc.get('status'),
              mensagem:        e.payload.doc.get('mensagem'),
              instalacao:      e.payload.doc.get('instalacao'),
              feito:           e.payload.doc.get('feito'),
              termino:         e.payload.doc.get('termino'),
              codigocliente:   e.payload.doc.get('credenciais.codigocliente'),
              fila:            e.payload.doc.get('fila'),
              screenlog:       e.payload.doc.get('screenlog'),
              tentativas:      e.payload.doc.get('historico'),     
              origem:          'jobs'
                             
              //this.afs.doc(`users/${this.curUser}/animals/${this.curPetID}`)
            };        
                
          })

          
          //this.dataSource.data = [];
          // this.dataSource.data = this.retorno;
          this.dataSource.data = this.retorno;
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.table.dataSource = this.dataSource;          
          
        });


        setTimeout(()=>{    
          subscription.unsubscribe()     // interrompe a assinatura depois de 5000ms     
        }, 5000);
        
              
       }

       async openModalConexoes(row)  {  // busca conexao


        var docRef = this.firestore.collection('/contratos/' + row.contrato + '/servicos/'+ row.servico+ '/instalacoes/').doc(row.instalacao);          
        
        docRef.get()
        .toPromise()
        .then(function(doc) {
          
          if (doc.exists) {            
              row.conexaoID=          doc.data().conexaoID            
          } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
          }
        })
        
        
        
        .then(() => { // busca provedor

          var docRef = this.firestore.collection('/contratos/' + row.contrato + '/servicos/').doc(row.servico);            
    
          docRef.get()
          .toPromise()
          .then(function(doc) {
            if (doc.exists) {
                       
              
                       
                if (doc.data().provedor != undefined) {
                    row.provedor = doc.data().provedor                    
                    console.log('provedor',row.provedor)
                }
                else  
                    row.provedor = null;                    
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
          
          })

          .then(() => { // busca url
            console.log('provedor-2',row.provedor)
            var docRef = this.firestore.collection('provedores').doc(row.provedor);            
      
            docRef.get()
            .toPromise()
            .then(function(doc) {
              if (doc.exists) {
                         
                         
                  if (doc.data().url != undefined) {
                      row.url = doc.data().url                    
                      console.log("Entrou URL", row.servico, row.url);
                  }
                  else  
                      row.url = null;                    
              } else {
                  // doc.data() will be undefined in this case
                  console.log("No such document!");
              }
            
            })
          })

                
        .then(() =>{        // busca instalacao     
          
          var docRef = this.firestore.collection('/contratos/' + row.contrato + '/servicos/'+ row.servico+ '/conexoes/').doc(row.conexaoID);            
      
          docRef.get()
          .toPromise()
          .then(function(doc) {
            if (doc.exists) {
                //console.log("Document data:", doc.data());
    
                row.nomeconexao = doc.data().nomeconexao;
                row.senha = doc.data().senha;
                
                if (doc.data().usuario != undefined)
                    row.usuario = doc.data().usuario
                else  
                    row.usuario = null;
                

                if (doc.data().codigocliente != undefined)
                    row.clienteacesso = doc.data().codigocliente
                else  
                    row.clienteacesso = null;
                
                  } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
          })


          .then(() =>{           // carrega modal conexoes

            //console.log('row',row)
    
              const dialogRef = this.dialog.open(ConexoesModalComponent, {
                width: '600px',
                height: '550px',
                data: row
              });
          
              dialogRef.afterClosed().subscribe(result => {
                console.log('The dialog was closed', result);
                if(result.event =='Atualizar'){                               // se o job avulso for confirmado, adiciona registro e roteia para tela de jobs
                  console.log('Entrou em Atualizar')
                  this.DbserviceService.update_DB('contratos/'+ result.data.contrato +'/servicos/'+result.data.servico+'/conexoes', 
                  { usuario: result.data.usuario,
                    senha: result.data.senha,                
                  }, result.data.conexaoID);
                  
                  this.openSnackBar('Dados atualizados','Fechar');
                //  this.router.navigate(['jobs' ]);
                };
              });
            })
            
            .catch(function(error) {
              console.log("Error getting document:", error);
            });   
          })
      
          
        })
  
        }




       async downloadScreenLog(nomearquivo) {

   
        console.log('nomearquivo',nomearquivo.screenlog)
        const fileRef = this.storage.ref(nomearquivo.screenlog);
        fileRef.getDownloadURL().subscribe(url => {
          console.log('url',url)
    
          window.open(url, "_blank");
    
      
        }) 
    
      }
}



export interface JobsItem {
  id: string,
  contrato: string,
  servico: string,
  provedor: string,
  nomeservico: string,
  cliente: string,
  baseMes: string,
  baseAno: string,
  robot: boolean,
  status: string,
  mensagem: string,
  instalacao: string,  
  feito: boolean,  
  termino: number,
  codigocliente: string,
  fila: string,
  origem: string,
  screenlog: string
}

const JOBS_DATA: JobsItem[] = [
];

