<div style="margin: 2rem;">
  <div>
    <h1> Processamento </h1>
   </div>
</div>

 
<div style="margin: 2rem;">

  <mat-form-field>
  <mat-label>Cliente</mat-label>
    <input disabled='true' matInput [(ngModel)]="clienteselected">
</mat-form-field>

<mat-form-field style="margin: 1rem;">
  <mat-label>Contrato</mat-label>
      <input disabled='true' matInput [(ngModel)]="nomecontrato">
</mat-form-field>


<mat-form-field style="margin: 1rem;">
  <mat-label>OCR Status</mat-label>
  <mat-select [(ngModel)]="statusOCRselected" (ngModelChange)="atualizaFiltro()"> 
  <mat-option disabled='false' *ngFor="let opcao of statusOCRlista" [value]="opcao">
      {{opcao}}
  </mat-option>
  </mat-select>
</mat-form-field>


<mat-form-field style="margin: 1rem;">
  <mat-label>Mês</mat-label>
  <mat-select [(ngModel)]="messelected" (ngModelChange)="atualizaFiltro()" multiple>
  <mat-option *ngFor="let opcao of meslista" [value]="opcao">
      {{opcao}}
  </mat-option>
  </mat-select>
</mat-form-field>

<mat-form-field style="margin: 1rem;">
  <mat-label>Ano</mat-label>
  <mat-select [(ngModel)]="anoselected" (ngModelChange)="atualizaFiltro()"> 
  <mat-option *ngFor="let opcao of anolista" [value]="opcao">
      {{opcao}}
  </mat-option>
  </mat-select>
</mat-form-field>
  
</div>

<div style="margin: 2rem;">


  <mat-form-field>
    <mat-label>Lote</mat-label>
    <mat-select [(ngModel)]="loteselected">
    <mat-option *ngFor="let opcao of lotelista" [value]="opcao">
        {{opcao}}
    </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field style="margin: 1rem;">
    <mat-label>Mês</mat-label>
    <mat-select [(ngModel)]="mesloteselected">
    <mat-option *ngFor="let opcao of meslista" [value]="opcao">
        {{opcao}}
    </mat-option>
    </mat-select>
  </mat-form-field>
  
  <mat-form-field style="margin: 1rem;">
    <mat-label>Ano</mat-label>
    <mat-select [(ngModel)]="anoloteselected" > 
    <mat-option *ngFor="let opcao of anolista" [value]="opcao">
        {{opcao}}
    </mat-option>
    </mat-select>
  </mat-form-field>

  <button style="margin: 1rem;" [disabled]="statusOCRselected=='pendente' ? false : true" mat-raised-button (click)="onClickProcessa()" color="primary"><mat-icon>play_arrow</mat-icon>Criar lote para Processamento</button>
  <button style="margin: 1rem;" mat-raised-button (click)="exporter.exportTable('xlsx', {fileName:'export', sheet: 'r3pa', Props: {Author: 'r3pa'}})" color="primary"><mat-icon>file_download</mat-icon> Exportar para Excel</button>

</div>

<div>

</div>

  
  <div class="mat-elevation-z8">
    <mat-table matTableExporter  [dataSource]="dataSource.data" mat-table class="full-width-table" #exporter="matTableExporter"  matSort aria-label="Elements">

      <!-- Id Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef >Job Id</th>
        <td mat-cell *matCellDef="let row">{{row.id}}</td>
      </ng-container>
  
      <!-- Id Column -->
      <ng-container matColumnDef="servico">
        <th mat-header-cell *matHeaderCellDef >Id do Servico</th>
        <td mat-cell *matCellDef="let row">{{row.servico}}</td>
      </ng-container>
  
      <!-- Id Column -->
      <ng-container matColumnDef="nomeservico">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome Servico</th>
        <td mat-cell *matCellDef="let row">{{row.nomeservico}}</td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="cliente">
        <th mat-header-cell *matHeaderCellDef >Cliente</th>
        <td mat-cell *matCellDef="let row">{{row.cliente}}</td>
      </ng-container>
  

      <!-- Name Column -->
      <ng-container matColumnDef="provedor">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Provedor</th>
        <td mat-cell *matCellDef="let row">{{row.provedor}}</td>
      </ng-container>
  
      <!-- Name Column -->
      <ng-container matColumnDef="baseMes">
        <th mat-header-cell *matHeaderCellDef >Mês</th>
        <td mat-cell *matCellDef="let row">{{row.baseMes}}</td>
      </ng-container>

        
      <!-- Name Column -->
      <ng-container matColumnDef="baseAno">
        <th mat-header-cell *matHeaderCellDef >Ano</th>
        <td mat-cell *matCellDef="let row">{{row.baseAno}}</td>
      </ng-container>


      <!-- Name Column 
      <ng-container matColumnDef="codigocliente">
        <th mat-header-cell *matHeaderCellDef >Codigo Cliente</th>
        <td mat-cell *matCellDef="let row">{{row.codigocliente}}</td>
      </ng-container>
      -->

      <!-- Name Column -->
      <ng-container matColumnDef="instalacao">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
        >Instalação</th>
        <td mat-cell *matCellDef="let row">{{row.instalacao}}</td>
      </ng-container>
    
      <!-- Name Column -->
      <ng-container matColumnDef="clienteinstalacao">
      <th mat-header-cell *matHeaderCellDef mat-sort-header
      >Cliente da instalação</th>
      <td mat-cell *matCellDef="let row">{{row.clienteinstalacao}}</td>
    </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="robot">
        <th mat-header-cell *matHeaderCellDef >Robô</th>
        <td mat-cell *matCellDef="let row">{{row.robot}}</td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let row"><mat-spinner class={{row.status}} diameter="20" value="100" mode="{{row.status == 'iniciado' ? 'indeterminate' : 'determinated' }}" ></mat-spinner></td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="tentativas">
        <th mat-header-cell *matHeaderCellDef >Tentativas</th>
        <td mat-cell *matCellDef="let row">{{row.tentativas == null ? 1 : row.tentativas.length + 1}}</td>  
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="mensagem">
        <th mat-header-cell *matHeaderCellDef >Mensagem processamento</th>
        <td mat-cell *matCellDef="let row">{{row.mensagem}}</td>
      </ng-container>

     
      <!-- Name Column -->
      <ng-container matColumnDef="feito">
        <th mat-header-cell *matHeaderCellDef>Concluído</th>
        <td mat-cell *matCellDef="let row">{{row.feito == true ? "Sim" : "Não" }}</td>
      </ng-container>
  
      <!-- Name Column -->
      <ng-container matColumnDef="termino">
        <th mat-header-cell *matHeaderCellDef >Término</th>
        <td mat-cell *matCellDef="let row">{{row.termino == null ? "" : row.termino.seconds*1000 | date:'dd/MM/yyyy | hh:mm a' }}</td>
      </ng-container>

      
      <!-- Name Column     -->
      <ng-container matColumnDef="acao">
        <th mat-header-cell *matHeaderCellDef>Ações</th>
        <td mat-cell *matCellDef="let element" class="action-link"  > 
          <button [disabled]="element.screenlog!=undefined ? false : true"  mat-stroked-button matTooltip="Screen log disponível" matTooltipClass="tooltip-blue"(click)="downloadScreenLog(element)" color="primary"><mat-icon>insert_photo</mat-icon></button> &nbsp;                
          <button mat-stroked-button matTooltip="Dados da instalação" matTooltipClass="tooltip-blue"(click)="openModalInstalacoes(element)" color="primary"><mat-icon>store_mall_directory</mat-icon></button> &nbsp;
          <button mat-stroked-button matTooltip="Dados da conexão" matTooltipClass="tooltip-blue"(click)="openModalConexoes(element)" color="primary"><mat-icon>lock</mat-icon></button> &nbsp; 
          <button [disabled]="element.status=='verificar' ? false : true"  mat-stroked-button matTooltip="reprocessar" matTooltipClass="tooltip-blue"(click)="pendenteJob(element)" color="accent"><mat-icon>rotate_right</mat-icon></button> &nbsp;        
          <button mat-stroked-button matTooltip="suspender" matTooltipClass="tooltip-blue"(click)="suspenderJob(element)" color="primary"><mat-icon>pause_circle_outline</mat-icon></button> &nbsp;                 
         <!--   <button [ngStyle]="{'color':element.comentario == null ?  '#e7993ff6' : '#5fbb6b'}" mat-stroked-button matTooltip="Comentario" matTooltipClass="tooltip-blue"  (click)="openComentario(element)" ><mat-icon>content_paste</mat-icon></button> &nbsp;    -->

        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="comentario">
      <th mat-header-cell *matHeaderCellDef >Comentário</th>
      <td mat-cell *matCellDef="let row">{{row.comentario}}</td>
    </ng-container>

  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </mat-table>

    <mat-paginator #paginator
    [length]="dataSource?.data.length"
    [pageIndex]="0"
    [pageSize]="20"
    [pageSizeOptions]="[20, 40, 80, 100]">
</mat-paginator>
  

</div>
  
<div>
  &nbsp;
</div>

  