import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {FirebaseUISignInFailure, FirebaseUISignInSuccessWithAuthResult} from 'firebaseui-angular';
import {AngularFireAuth} from '@angular/fire/auth';
import {Router} from '@angular/router';
import { first } from 'rxjs/operators';
import { NgxAuthFirebaseUIModule } from 'ngx-auth-firebaseui';

@Injectable({
  providedIn: 'root'
})
export class MyGuard implements CanActivate {

  constructor(public afAuth: AngularFireAuth, private router: Router) {
   
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      console.log('canActivate log: ');
    return this.autorizado();
  }
  
  isLoggedIn() {
     return this.afAuth.authState.pipe(first()).toPromise();
  }
  
  async autorizado() {
     const user = await this.isLoggedIn()
     if (user) {
      console.log('user guard is logged in ');
      
      return true;

     } else {
      console.log('user guard not logged in');
      return false;
    }
  }




}
 
