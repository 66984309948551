import { AfterViewInit, Component, OnInit, ViewChild, OnChanges } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { AngularFireStorage } from '@angular/fire/storage';
//import { JobsDataSource, JobsItem } from './jobs-datasource';
import { DbserviceService } from '../services/dbservice.service';
import { MatIconModule} from '@angular/material/icon';
import { FormControl } from "@angular/forms";
import { ElementRef} from '@angular/core';
import { MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {ThemePalette} from '@angular/material/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ChangeDetectorRef } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ComentarioComponent } from '../modals/comentario/comentario.component';
import { InstalacoesModalComponent } from '../modals/instalacoes/instalacoes.component';
import { ConexoesModalComponent } from '../modals/conexoes/conexoes.component';
import { AngularFirestore } from '@angular/fire/firestore';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { environment } from '../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Component({
  selector: 'app-processamento',
  templateUrl: './processamento.component.html',
  styleUrls: ['./processamento.component.scss'],
})
export class ProcessamentoComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<JobsItem>;

  displayedColumns = [ 'nomeservico','cliente','provedor','baseMes', 'baseAno', 'instalacao','id', 'feito' , 'termino',];
  //displayedColumns = ['position', 'name', 'weight', 'symbol'];
  //dataSource: MatTableDataSource<Element> ;
  dataSource: MatTableDataSource<JobsItem> ;
  
  tamanho = 0;
  //dataSource: JobsDataSource;   
  //dataSource: MatTableDataSource<JobsItem>;
  //jobs: [];
  contratolista: string[] = ['FPblpv89ZrWuDpS6RfoI'];
  contratoselected = 'FPblpv89ZrWuDpS6RfoI';
  clientelista: string[] = ['Ação Engenharia'];
  clienteselected = 'Ação Engenharia';
  nomecontrato = 'acao-engenharia';
  
  ContratoSelecionado: string;
  isChecked = true;
  statusOCRlista: string[] = ['esperando entrega','pendente', 'processando'];
  verificar = 'verificar';
  payload = {};
  
  servicos: string[] = ['servico-bb-celesc',
                      'servico-bb-cemig',
                      'servico-bb-coelba-b',
                      'servico-bb-copel',
                      'servico-bb-cpfl-leste-paulista-a',
                      'servico-bb-cpfl-leste-paulista-b',
                      'servico-bb-cpfl-paulista-a',
                      'servico-bb-cpfl-paulista-b',
                      'servico-bb-cpfl-piratininga-a',
                      'servico-bb-cpfl-piratininga-b',
                      'servico-bb-cpfl-santa-cruz-a',
                      'servico-bb-cpfl-santa-cruz-b',
                      'servico-bb-cpfl-sul-paulista-a',
                      'servico-bb-elektro',
                      'servico-bb-elektro',
                      'servico-bb-enel-go',
                      'servico-bb-enel-sp',
                      'servico-bb-light',
                      'servico-bb-rge-a',
                      'servico-bb-rge-b',
                      'servico-bb-rge-sul-a',
                      'servico-bb-rge-sul-b',
                      'servico-itau-celesc',
                      'servico-itau-cemig',
                      'servico-itau-copel',
                      'servico-itau-cpfl-paulista-a',
                      'servico-itau-cpfl-paulista-b',
                      'servico-itau-cpfl-piratininga-a',
                      'servico-itau-cpfl-piratininga-b',
                      'servico-itau-cpfl-santa-cruz-a',
                      'servico-itau-cpfl-santa-cruz-b',
                      'servico-itau-elektro',
                      'servico-itau-enel-go',
                      'servico-itau-enel-sp',
                      'servico-itau-light',
                      'servico-itau-rge-a',
                      'servico-itau-rge-b',
                      'servico-itau-rge-sul-a',
                      'servico-itau-rge-sul-b',
                      'servico-itau-sabesp']
  statusselected = 'verificar';
  loteselected = '';
  meslista: string[] = ['01','02','03','04','05','06','07','08','09','10', '11','12'];
  lotelista: string[] = ['1','2','3','4','5','6','7','8','9','10', '11','12','13','14','15'];
  messelected: string[] = [];
  anolista: string[] = ['2023','2022','2021','2020','2019'];
  mesloteselected: string = '';
  anoselected: string = '2023';
  anoloteselected: string = '';
  statusOCRselected = 'pendente';
  retorno:any;
  jobsitem: JobsItem[];  
  sliceinicio = 0;
  slicefim = 20;
  url:string;
  lote:string;
  
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json',  'Access-Control-Allow-Origin': '*'})
  };
  


  
  //select: FormControl = new FormControl(this.instalacaolista);

  constructor(private DbserviceService: DbserviceService,
              private router: Router,
              private storage: AngularFireStorage,
              private _snackBar: MatSnackBar,
              public dialog: MatDialog,
              private httpClient: HttpClient,
              private firestore: AngularFirestore)
  {  this.dataSource = new MatTableDataSource()
   
   
  }

   ngOnInit() {
    this.dataSource.data = JOBS_DATA  
    
        
  }

  ngAfterViewInit() {
   this.dataSource.sort = this.sort;
   this.dataSource.paginator = this.paginator;
 
  }

  openServicosDetalhes(row) {
    console.log(row);
    this.router.navigate(['servicedetails', row ]);
  }

  reprocessarJob(row){
    this.DbserviceService.atualizarJob(row.id,'r3pa-job','pendente');
    this.openSnackBar('Job enviado para reprocessamento.','Fechar');
    //this.router.navigate(['jobs' ]);     
    
  }

  pendenteJob(row){
    this.DbserviceService.alteraStatusJob(row.id,'pendente');
    this.openSnackBar('Job enviado para reprocessamento','Fechar');
    //this.router.navigate(['jobs' ]);     
    this.atualizaFiltro()
  }    

  verificarJob(row){
    this.DbserviceService.alteraStatusJob(row.id,'verificar');
    this.openSnackBar('Job enviado para verificação','Fechar');
    //this.router.navigate(['jobs' ]);     
    this.atualizaFiltro()    
  }

  suspenderJob(row){
    this.DbserviceService.alteraStatusJob(row.id,'suspenso');
    this.openSnackBar('Job suspenso','Fechar');
    //this.router.navigate(['jobs' ]);     
    this.atualizaFiltro()    
  }


  openSnackBar(mensagem, saida) {
    this._snackBar.open(mensagem, saida, {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  async atualizaFiltro(){
    console.log('antes 3')
        //this.dataSource.data = [];
        const subscription = this.DbserviceService.read_Jobs_whereOCRStatus('/jobs',this.statusOCRselected,this.messelected, this.anoselected).subscribe(data => {
          this.retorno = data.map(e => {
            return {     
              id:              e.payload.doc.id,
              contrato:        e.payload.doc.get('contrato'),
              servico:         e.payload.doc.get('servico'),
              provedor:        e.payload.doc.get('provedor'),
              nomeservico:     e.payload.doc.get('nomeservico'),
              cliente:         e.payload.doc.get('cliente'),
              //clienteinstalacao:  e.payload.doc.get('clienteinstalacao'),
              baseMes:         e.payload.doc.get('baseMes'),   
              baseAno:         e.payload.doc.get('baseAno'),   
              //robot:           e.payload.doc.get('robot'),
              status:          e.payload.doc.get('status'),
              //mensagem:        e.payload.doc.get('mensagem'),
              instalacao:      e.payload.doc.get('instalacao'),
              feito:           e.payload.doc.get('feito'),
              termino:         e.payload.doc.get('termino'),
              //codigocliente:   e.payload.doc.get('credenciais.codigocliente'),
              //fila:            e.payload.doc.get('fila'),
              //screenlog:       e.payload.doc.get('screenlog'),
              //comentario:      e.payload.doc.get('comentarioverificacao'),
              //tentativas:      e.payload.doc.get('historico'),              
              //origem:          'jobs'




                             
              //this.afs.doc(`users/${this.curUser}/animals/${this.curPetID}`)
            };        
                
          })

          
          //this.dataSource.data = [];
          // this.dataSource.data = this.retorno;
          this.dataSource.data = this.retorno;
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.table.dataSource = this.dataSource;          
          
        });


        setTimeout(()=>{    
          subscription.unsubscribe()     // interrompe a assinatura depois de 5000ms     
        }, 5000);
        
              
       }

       async downloadScreenLog(nomearquivo) {

   
        console.log('nomearquivo',nomearquivo.screenlog)
        const fileRef = this.storage.ref(nomearquivo.screenlog);
        fileRef.getDownloadURL().subscribe(url => {
          console.log('url',url)
    
          window.open(url, "_blank");
    
      
        }) 
    
      }



    openComentario(row): void {
      const dialogRef = this.dialog.open(ComentarioComponent, {
        width: '600px',
        data: row
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed', result);
        if(result!=null){                               // se o job avulso for confirmado, adiciona registro e roteia para tela de jobs
          this.DbserviceService.UpdateServico('contratos/'+ result.contrato +'/servicos/'+result.servico, result.atualizacao);
          console.log('contratos/'+ result.contrato +'/servicos/'+result.id);
          this.openSnackBar('Dados atualizados','Fechar');
        //  this.router.navigate(['jobs' ]);
        };
      });
    }


    openModalInstalacoes(row): void {

      var docRef = this.firestore.collection('/contratos/' + row.contrato + '/servicos/'+ row.servico+ '/instalacoes/').doc(row.instalacao);          
      
      docRef.get()
      .toPromise()
      .then(function(doc) {
        if (doc.exists) {            

            row.diaultimaleitura =  doc.data().diaultimaleitura
            row.inicioMes =         doc.data().inicioMes
            row.inicioAno=          doc.data().inicioAno
            row.conexaoID=          doc.data().conexaoID
            row.ativo=              doc.data().ativo
            
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
      }).then(() =>{     

        const dialogRef = this.dialog.open(InstalacoesModalComponent, {
          width: '600px',
          height: '550px',
          data: row
        });
    
        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed', result);
          if(result.event =='Atualizar'){                               // se o job avulso for confirmado, adiciona registro e roteia para tela de jobs
            console.log('Entrou em Atualizar')
            this.DbserviceService.update_DB('contratos/'+ result.data.contrato +'/servicos/'+result.data.servico+'/instalacoes', 
            {clienteinstalacao: result.data.clienteinstalacao, 
            diaultimaleitura: result.data.diaultimaleitura,
            conexaoID: result.data.conexaoID,
            inicioAno: result.data.inicioAno,
            inicioMes: result.data.inicioMes,
            ativo: result.data.ativo,
            }, result.data.instalacao);
            
            this.openSnackBar('Dados atualizados','Fechar');
          //  this.router.navigate(['jobs' ]);
          };
        });
      })
    }

    async openModalConexoes(row)  {  // busca conexao


      var docRef = this.firestore.collection('/contratos/' + row.contrato + '/servicos/'+ row.servico+ '/instalacoes/').doc(row.instalacao);          
      
      docRef.get()
      .toPromise()
      .then(function(doc) {
        
        if (doc.exists) {            
            row.conexaoID=          doc.data().conexaoID            
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
      })
      
      
      
      .then(() => { // busca provedor

        var docRef = this.firestore.collection('/contratos/' + row.contrato + '/servicos/').doc(row.servico);            
  
        docRef.get()
        .toPromise()
        .then(function(doc) {
          if (doc.exists) {
                     
            
                     
              if (doc.data().provedor != undefined) {
                  row.provedor = doc.data().provedor                    
                  console.log('provedor',row.provedor)
              }
              else  
                  row.provedor = null;                    
          } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
          }
        
        })

        .then(() => { // busca url
          console.log('provedor-2',row.provedor)
          var docRef = this.firestore.collection('provedores').doc(row.provedor);            
    
          docRef.get()
          .toPromise()
          .then(function(doc) {
            if (doc.exists) {
                       
                       
                if (doc.data().url != undefined) {
                    row.url = doc.data().url                    
                    console.log("Entrou URL", row.servico, row.url);
                }
                else  
                    row.url = null;                    
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
          
          })
        })

              
      .then(() =>{        // busca instalacao     
        
        var docRef = this.firestore.collection('/contratos/' + row.contrato + '/servicos/'+ row.servico+ '/conexoes/').doc(row.conexaoID);            
    
        docRef.get()
        .toPromise()
        .then(function(doc) {
          if (doc.exists) {
              //console.log("Document data:", doc.data());
  
              row.nomeconexao = doc.data().nomeconexao;
              row.senha = doc.data().senha;
              
              if (doc.data().usuario != undefined)
                  row.usuario = doc.data().usuario
              else  
                  row.usuario = null;
              

              if (doc.data().codigocliente != undefined)
                  row.clienteacesso = doc.data().codigocliente
              else  
                  row.clienteacesso = null;
              
                } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
          }
        })


        .then(() =>{           // carrega modal conexoes

          //console.log('row',row)
  
            const dialogRef = this.dialog.open(ConexoesModalComponent, {
              width: '600px',
              height: '550px',
              data: row
            });
        
            dialogRef.afterClosed().subscribe(result => {
              console.log('The dialog was closed', result);
              if(result.event =='Atualizar'){                               // se o job avulso for confirmado, adiciona registro e roteia para tela de jobs
                console.log('Entrou em Atualizar')
                this.DbserviceService.update_DB('contratos/'+ result.data.contrato +'/servicos/'+result.data.servico+'/conexoes', 
                { usuario: result.data.usuario,
                  senha: result.data.senha,                
                }, result.data.conexaoID);
                
                this.openSnackBar('Dados atualizados','Fechar');
              //  this.router.navigate(['jobs' ]);
              };
            });
          })
          
          .catch(function(error) {
            console.log("Error getting document:", error);
          });   
        })
    
        
      })

      }

      

       onClickProcessa()  {
        

        if (!(this.loteselected=='' || this.mesloteselected == '' || this.anoloteselected == '')) {

          if (this.dataSource.data.length > 0) {

            this.payload = {
              lote: this.loteselected, 
              baseAno: this.anoloteselected,
              baseMes: this.mesloteselected,
              jobs: this.dataSource.data,
              ocrEntregue: false,
              status: 'pendente'
              
            };
            
            console.log('payload',this.payload)

            this.DbserviceService.jobProcessamento('ocrjobs',this.payload,undefined).then((retorno) => {

             });
            }
            else
            {
              this.openSnackBar('Nenhum job foi selecionado','Fechar');

            }
        }
        else {
          this.openSnackBar('Informe os dados do lote','Fechar');

        }


      }



    
    


      //updateCar(car: Car): Observable<Car> {
       // return this.httpClient.put<Car>(this.url + '/' + car.id, JSON.stringify(car), this.httpOptions)
      //    .pipe(
      //      retry(1),
       //     catchError(this.handleError)
      //   )
      //}

    
      

    
      // Manipulação de erros
      handleError(error: HttpErrorResponse) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // Erro ocorreu no lado do client
          errorMessage = error.error.message;
        } else {
          // Erro ocorreu no lado do servidor
          errorMessage = `Código do erro: ${error.status}, ` + `menssagem: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    };



}



export interface JobsItem {
  id: string,
  contrato: string,
  servico: string,
  provedor: string,
  nomeservico: string,
  cliente: string,
  baseMes: string,
  baseAno: string,
  robot: boolean,
  status: string,
  mensagem: string,
  instalacao: string,  
  feito: boolean,  
  termino: number,
  codigocliente: string,
  fila: string,
  origem: string,
  screenlog: string
}

const JOBS_DATA: JobsItem[] = [
];

