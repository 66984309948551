<div style="margin: 2rem;">

  <div>
    <h1> Serviços </h1>
  </div>
</div>


<div style="margin: 2rem;">
  
    <mat-form-field>
    <mat-label>Cliente</mat-label>
      <input disabled='true' matInput [(ngModel)]="clienteselected">
  </mat-form-field>
  
  
  <mat-form-field>
    <mat-label>Contrato</mat-label>
        <input disabled='true' matInput [(ngModel)]="nomecontrato">
  </mat-form-field>
  
</div>
  


  <div class="mat-elevation-z8">
    <table [dataSource]="dataSource.data" mat-table class="full-width-table" matSort aria-label="Elements">

      <!-- Id Column -->
      <ng-container matColumnDef="ativo">
        <th mat-header-cell *matHeaderCellDef >Ativo</th>
        <td mat-cell *matCellDef="let row"><mat-icon> {{row.ativo == true ? "done" : "clear" }}</mat-icon></td>     
        
      </ng-container>

      <!-- Id Column -->
      <ng-container matColumnDef="nomeservico">
        <th mat-header-cell *matHeaderCellDef >Nome</th>
        <td mat-cell *matCellDef="let row">{{row.nomeservico}}</td>           
      </ng-container>
      
      <!-- Id Column -->
      <ng-container matColumnDef="inicioMes">
        <th mat-header-cell *matHeaderCellDef >Mês Inicial</th>
        <td mat-cell *matCellDef="let row">{{row.inicioMes}}</td>           
      </ng-container>
      
      <!-- Id Column -->
      <ng-container matColumnDef="inicioAno">
        <th mat-header-cell *matHeaderCellDef >Ano Inicial</th>
        <td mat-cell *matCellDef="let row">{{row.inicioAno}}</td>           
      </ng-container>
      
      <!-- Id Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef >Id do Serviço (API service token id)</th>
        <td mat-cell *matCellDef="let row">{{row.id}}</td>
      </ng-container>
  
      <!-- Name Column -->
      <ng-container matColumnDef="provedor">
        <th mat-header-cell *matHeaderCellDef >Provedor</th>
        <td mat-cell *matCellDef="let row">{{row.provedor}}</td>
      </ng-container>
  
      <!-- Name Column -->
      <ng-container matColumnDef="cliente">
        <th mat-header-cell *matHeaderCellDef >Cliente</th>
        <td mat-cell *matCellDef="let row">{{row.cliente}}</td>
      </ng-container>
 
      <!-- Name Column -->
      <ng-container matColumnDef="download">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Download Robot</th>
        <td mat-cell *matCellDef="let row"><mat-icon> {{row.download == true ? "check_box" : "check_box_outline_blank" }}</mat-icon></td>         
      </ng-container>
  

      <!-- Name Column -->
      <ng-container matColumnDef="ocr">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>OCR Robot</th>
        <td mat-cell *matCellDef="let row"><mat-icon> {{row.ocr == true ? "check_box" : "check_box_outline_blank" }}</mat-icon></td>     
      </ng-container>
  

      <ng-container matColumnDef="acao">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="action-link"> 
          <button [disabled]="!element.ativo" mat-stroked-button matTooltip="Resumo" matTooltipClass="tooltip-blue" (click)="openResumo(element)" color="primary" ><mat-icon>assignment</mat-icon></button> &nbsp;
         <!-- <button [disabled]="!element.ativo" mat-stroked-button matTooltip="Dados de acesso" matTooltipClass="tooltip-blue" (click)="openDadosacesso(element)" color="accent" ><mat-icon>settings</mat-icon></button> &nbsp;-->
          <button [disabled]="!element.ativo" mat-stroked-button matTooltip="Instalações" matTooltipClass="tooltip-blue"(click)="openInstalacoes(element)" color="primary"><mat-icon>store_mall_directory</mat-icon></button> &nbsp;
         <!--  <button [disabled]="true" mat-stroked-button matTooltip="Job Avulso" matTooltipClass="tooltip-blue"(click)="openQuickJob(element)" color="accent"><mat-icon>rotate_right</mat-icon></button> &nbsp;-->
          <button [disabled]="!element.ativo" mat-stroked-button matTooltip="Documentos disponíveis" matTooltipClass="tooltip-blue"(click)="openServicosDetalhes(element)" color="primary"><mat-icon>picture_as_pdf</mat-icon></button> &nbsp;        
        </td>
      </ng-container>

      
      <button mat-raised-button color="primary">Primary</button>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  
    <mat-paginator #paginator
        [length]="dataSource?.data.length"
        [pageIndex]="0"
        [pageSize]="20"
        [pageSizeOptions]="[20, 40, 80, 100]">
    </mat-paginator>
</div>



<div>
  &nbsp;
</div>


  