import { DataSource } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { map } from 'rxjs/operators';
import { Observable, of as observableOf, merge } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { TestBed } from '@angular/core/testing';
import { DbserviceService } from '../services/dbservice.service';
import { Timestamp } from 'rxjs/internal/operators/timestamp';



/*

console.log('Passou 1');
const EXAMPLE_DATA = .students;

console.log('payload: ' + students);
*/
// TODO: Replace this with your own data model type
export interface OcrJobsdetailsItem {
  id: string;
  cliente: string;
  iniciocontrato: Date;
  terminocontrato: Date;
  clienteservico: string;
  download: boolean;
  ocr: boolean;
}
const EXAMPLE_DATA: OcrJobsdetailsItem[] = [];

/**
 * Data source for the Tabela view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class OcrJobsdetailsDataSource extends DataSource<OcrJobsdetailsItem> {
  data: OcrJobsdetailsItem[] = EXAMPLE_DATA;
  paginator: MatPaginator;
  sort: MatSort;
  

  constructor() {
    super();
    
    //comment
  } 
  //TabelaItem[] = data;
  

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<OcrJobsdetailsItem[]> {
    // Combine everything that affects the rendered data into one update
    // stream for the data-table to consume.
    const dataMutations = [
      observableOf(this.data),
      this.paginator.page,
      this.sort.sortChange
    ];

    return merge(...dataMutations).pipe(map(() => {
      return this.getPagedData(this.getSortedData([...this.data]));
    }));
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect() {}

  /**
   * Paginate the data (client-side). If you're using server-side pagination,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getPagedData(data: OcrJobsdetailsItem[]) {
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    return data.splice(startIndex, this.paginator.pageSize);
  }

  /**
   * Sort the data (client-side). If you're using server-side sorting,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getSortedData(data: OcrJobsdetailsItem[]) {
    if (!this.sort.active || this.sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      const isAsc = this.sort.direction === 'asc';
      switch (this.sort.active) {
        //case 'notafiscal': return compare(a.notafiscal, b.notafiscal, isAsc);
        //case 'leitatu': return compare(+a.leitatu, +b.leitatu, isAsc);
        //case 'leitant': return compare(+a.leitant, +b.leitant, isAsc);
        default: return 0;
      }
    });
  }
}

/** Simple sort comparator for example ID/Name columns (for client-side sorting). */
function compare(a: string | number, b: string | number, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
