<div iclass="grid-container" > 
  <h1 class="mat-h1">Dashboard</h1>
  <!--
  <div>
    <button mat-raised-button (click)="updateGraph(this.saida)" color="primary"><mat-icon>file_download</mat-icon> Atualizar</button>      
  
  </div>
  -->
  <mat-form-field>
    <mat-label>Mês</mat-label>
    <mat-select [(ngModel)]="messelected" (ngModelChange)="updateGraph(this.saida)" > 
    <mat-option *ngFor="let opcao of meslista" [value]="opcao">
        {{opcao}}
    </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-grid-list cols="2" rowHeight="350px" >
    <mat-grid-tile *ngFor="let card of cards | async ; let i = index" [colspan]="card.cols" [rowspan]="card.rows">
      <mat-card class="dashboard-card">
        <mat-card-header>
          <mat-card-title>
           
            <button mat-icon-button class="more-button" [matMenuTriggerFor]="menu" aria-label="Toggle menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu" xPosition="before">
              <button mat-menu-item>Expand</button>
              <button mat-menu-item>Remove</button>
            </mat-menu>
          </mat-card-title>
        </mat-card-header>

        <mat-card-content class="dashboard-card-content">
          <div  >


            <div  >
              <div id="cards" echarts [options]="controleoptions[i]" class="demo-chart"></div>
            </div>


            

          </div>
        </mat-card-content>
      </mat-card>
    </mat-grid-tile>
  </mat-grid-list>
</div>
