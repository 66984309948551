<div style="margin: 2rem;">
  <div>
    <h1> Clientes </h1>
  </div>
</div>

<form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
  <mat-card class="standard-card">  
    
  <mat-card-content>
    <div class="row">
      <div class="col">
        <mat-form-field class="full-width">
          <input matInput placeholder="ID" formControlName="id">
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <mat-form-field class="full-width">
          <input matInput placeholder="Nome" formControlName="nome">
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <mat-form-field class="full-width">
          <input matInput placeholder="CNPJ" formControlName="cnpj">
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <mat-form-field class="full-width">
          <input matInput placeholder="Razão Social" formControlName="razaosocial">
        </mat-form-field>
      </div>
    </div>

  </mat-card-content>
  <mat-card-actions>
    <button mat-stroked-button matTooltipClass="tooltip-blue" (click)="updateCliente(profileForm.value,profileForm.value.id)" color="primary">Gravar</button> &nbsp;        
  </mat-card-actions>  
</mat-card>
</form>

  
  
  

  