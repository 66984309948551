import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavegacaoComponent } from './navegacao/navegacao.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { TabelaComponent } from './tabela/tabela.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { TreeComponent } from './tree/tree.component';
import { MatTreeModule } from '@angular/material/tree';
import { AddressComponent } from './address/address.component';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { ReactiveFormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { environment } from '../environments/environment';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { OAuthModule } from 'angular-oauth2-oidc';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { FormsModule } from '@angular/forms';
import { NgxEchartsModule } from 'ngx-echarts';
import * as echarts from 'echarts';
import { AngularFireAuthModule} from '@angular/fire/auth';
import { FirebaseUIModule, firebase, firebaseui} from 'firebaseui-angular';
import { ContratosComponent } from './contratos/contratos.component';
import { OcrJobsComponent } from './ocrjobs/ocrjobs.component';
import { OcrJobsdetailsComponent } from './ocrjobsdetails/ocrjobsdetails.component';
import { JobsComponent } from './jobs/jobs.component';
import { VerificacaoComponent } from './verificacao/verificacao.component';
import { ProcessamentoComponent } from './processamento/processamento.component';
import { ServicedetailsComponent } from './servicedetails/servicedetails.component';
import { InstalacoesComponent } from './instalacoes/instalacoes.component';
import { StatusComponent } from './status/status.component';
import { MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { QuickjobComponent } from './modals/quickjob/quickjob.component';
import { DadosacessoComponent } from './modals/dadosacesso/dadosacesso.component';
import { ResumoComponent } from './modals/resumo/resumo.component';
import { ComentarioComponent } from './modals/comentario/comentario.component';
import { TentativasComponent } from './modals/tentativas/tentativas.component';
import { InstalacoesModalComponent } from './modals/instalacoes/instalacoes.component';
import { ConexoesModalComponent } from './modals/conexoes/conexoes.component';
import { DialogBoxComponent } from './modals/instalacoes/dialog-box.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DashboardUserComponent } from './dashboard-user/dashboard-user.component';
import { ClientesFormComponent } from './cadastros/clientes/form/clientesform.component';
import { ClientesTableComponent } from './cadastros/clientes/table/clientestable.component';
import { ProvedoresFormComponent } from './cadastros/provedores/form/provedoresform.component';
import { ProvedoresTableComponent } from './cadastros/provedores/table/provedorestable.component';
import { NgxAuthFirebaseUIModule } from 'ngx-auth-firebaseui';
import { MatTableExporterModule } from 'mat-table-exporter';

const firebaseUiAuthConfig: firebaseui.auth.Config = {
  signInFlow: 'popup',
  
  signInOptions: [
    {
      requireDisplayName: false,
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID
    },
 
  ],
  tosUrl: '<your-tos-link>',
  privacyPolicyUrl: '<your-privacyPolicyUrl-link>',
  //credentialHelper: firebaseui.auth.CredentialHelper.ACCOUNT_CHOOSER_COM
  credentialHelper: firebaseui.auth.CredentialHelper.NONE
};


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavegacaoComponent,
    TabelaComponent,
    DashboardComponent,
    TreeComponent,
    AddressComponent,
    ContratosComponent,
    OcrJobsComponent,
    OcrJobsdetailsComponent,
    JobsComponent,
    VerificacaoComponent,
    ProcessamentoComponent,
    ServicedetailsComponent,
    InstalacoesComponent,
    StatusComponent,
    QuickjobComponent,
    DadosacessoComponent,
    InstalacoesModalComponent,
    ConexoesModalComponent,
    DialogBoxComponent,
    DashboardUserComponent,
    ClientesFormComponent,
    ClientesTableComponent,
    ProvedoresFormComponent,
    ProvedoresTableComponent,
    ResumoComponent,
    ComentarioComponent,
    TentativasComponent,
    
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatTableModule,
    MatTableExporterModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatTreeModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    HttpClientModule,
    OAuthModule.forRoot(),
    HttpModule,
    FormsModule,
    NgxEchartsModule.forRoot({
      echarts,
    }),
    FirebaseUIModule.forRoot(firebaseUiAuthConfig),
    AngularFireAuthModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatSnackBarModule,
    MatTooltipModule,
    NgxAuthFirebaseUIModule.forRoot(environment.firebaseConfig,
      () => 'r3pa-web',
     {
       enableFirestoreSync: true, // enable/disable autosync users with firestore
       toastMessageOnAuthSuccess: false, // whether to open/show a snackbar message on auth success - default : true
       toastMessageOnAuthError: false, // whether to open/show a snackbar message on auth error - default : true
       authGuardFallbackURL: '/loggedout', // url for unauthenticated users - to use in combination with canActivate feature on a route
       authGuardLoggedInURL: '/loggedin', // url for authenticated users - to use in combination with canActivate feature on a route
       passwordMaxLength: 60, // `min/max` input parameters in components should be within this range.
       passwordMinLength: 8, // Password length min/max in forms independently of each componenet min/max.
       // Same as password but for the name
       nameMaxLength: 50,
       nameMinLength: 6,
       // If set, sign-in/up form is not available until email has been verified.
       // Plus protected routes are still protected even though user is connected.
       guardProtectedRoutesUntilEmailIsVerified: false,
       enableEmailVerification: false, // default: true
     }),
   
  ],
  providers: [
  
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
