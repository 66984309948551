<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false">
    <mat-toolbar><mat-icon>api</mat-icon>&nbsp;r3pa {{ambiente}}</mat-toolbar>
    <mat-toolbar>Ação Engenharia</mat-toolbar>
    <mat-nav-list>
      <a mat-list-item routerLink="./dashboard"><mat-icon>insert_chart_outlined</mat-icon>&nbsp;Dashboard</a>
      <!-- <a mat-list-item routerLink="./clientes"><mat-icon>business_center</mat-icon>&nbsp;Clientes</a>-->      
      <a mat-list-item routerLink="./provedores"><mat-icon>build</mat-icon>&nbsp;Provedores</a>
      <a mat-list-item routerLink="./instalacoes" [queryParams]="{origem: 'buscainstalacao'}" ><mat-icon>search</mat-icon>&nbsp;Busca Instalação</a>
      <a mat-list-item routerLink="./contratos"><mat-icon>reorder</mat-icon>&nbsp;Serviços</a>
      <a mat-list-item routerLink="./verificacao"><mat-icon>content_paste</mat-icon>&nbsp;Verificação</a>
      <!--  <a mat-list-item routerLink="./processamento"><mat-icon>play_arrow</mat-icon>&nbsp;Processamento</a>  -->
       <!-- <a mat-list-item routerLink="./ocrjobs"><mat-icon>play_arrow</mat-icon>&nbsp;OCR Jobs</a>  -->
      <a mat-list-item routerLink="./jobs"><mat-icon>rotate_right</mat-icon>&nbsp;Jobs</a>
       <!-- <a mat-list-item routerLink="./status"><mat-icon>rotate_right</mat-icon>&nbsp;Status</a>-->
      <!-- <a mat-list-item routerLink="./tabela"><mat-icon>remove_red_eye</mat-icon>&nbsp;OCR</a>-->
      <!-- <a mat-list-item routerLink="./dashboard-user"><mat-icon>bar_chart</mat-icon>&nbsp;Dashboard-User</a>-->
      <a mat-list-item routerLink="./home"><mat-icon>settings</mat-icon>&nbsp;Configurações</a>
      <!-- <a mat-list-item routerLink="./address"><mat-icon>business_center</mat-icon>&nbsp;Address</a>-->
      <a (click)="logout()" mat-list-item routerLink="./home"><mat-icon>close</mat-icon>&nbsp;Sair</a>
      
  

    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span>rapid, robust and reliable process automation - API services</span>
    </mat-toolbar>
    <router-outlet></router-outlet>


   
  </mat-sidenav-content>
</mat-sidenav-container>

