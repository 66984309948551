<h1 mat-dialog-title>Instalação</h1>

<div mat-dialog-content>
    
    <p>Atualize os dados da instalação:</p>
    
    <div class="row">
            
        <div class="col">
            <mat-form-field>
                <mat-label>Serviço</mat-label>
                <input disabled='true' matInput [(ngModel)]="local_data.nomeservico">
            </mat-form-field>
        </div>

        <div class="col">
            <mat-form-field>
                <mat-label>ID do serviço</mat-label>
                <input disabled='true' matInput [(ngModel)]="local_data.servico">
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <mat-form-field>
                <mat-label>Cliente</mat-label>
                <input disabled='true' matInput [(ngModel)]="local_data.cliente">
            </mat-form-field>
        </div>
    
        <div class="col">
            <mat-form-field>
                <mat-label>Provedor</mat-label>
                <input disabled='true' matInput [(ngModel)]="local_data.provedor">
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <mat-form-field>
                <mat-label>Instalação</mat-label>
                <input disabled='true' matInput [(ngModel)]="local_data.instalacao">
            </mat-form-field>
        </div>
    
        <div class="col">
            <mat-form-field>
                <mat-label>Cliente Instalação</mat-label>
                <input disabled='false' matInput [(ngModel)]="local_data.clienteinstalacao">
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <mat-form-field>
                <mat-label>Dia última emissão</mat-label>
                <input disabled='false' matInput [(ngModel)]="local_data.diaultimaleitura">
            </mat-form-field>
        </div>
    
        <div class="col">
            <mat-form-field>
                <mat-label>Conexão</mat-label>
                <input disabled='true' matInput [(ngModel)]="local_data.conexaoID">
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <mat-form-field>
                <mat-label>Mês início</mat-label>
                <input disabled='false' matInput [(ngModel)]="local_data.inicioMes">
            </mat-form-field>
        </div>
    
        <div class="col">
            <mat-form-field>
                <mat-label>Ano Início</mat-label>
                <input disabled='false' matInput [(ngModel)]="local_data.inicioAno">
            </mat-form-field>
        </div>
    </div>


    <div class="row">
        <div class="col">
            <mat-form-field>
                <mat-label>Ativo</mat-label>
                
                <mat-icon matSuffix (click)="local_data.ativo = !local_data.ativo">{{local_data.ativo ? 'check_box' : 'check_box_outline_blank'}}</mat-icon>

                <input disabled='true' matInput [(ngModel)]="local_data.ativo">
            </mat-form-field>
        </div>
    
    </div>



    <div class="row">
        <div class="col">
            <button mat-button (click)="doAction('Cancelar')" mat-flat-button color="accent">Cancelar</button>
        </div>
        <div class="col">
            <button mat-button (click)="doAction('Atualizar')" mat-flat-button color="primary">Gravar</button>
        </div>


    </div>



</div>

