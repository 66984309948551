import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DbserviceService } from '../../services/dbservice.service';

@Component({
  selector: 'app-quickjob',
  templateUrl: './quickjob.component.html',
  styleUrls: ['./quickjob.component.scss']
})

export class QuickjobComponent {

  instalacoes: any;
  instalacaolista: string[];
  instalacaoselected: string;
  meslista: string[] = ['01','02','03','04','05','06','07','08','09','10', '11','12']
  messelected: string = '01'
  anolista: string[] = ['2021','2020','2019'];
  anoselected: string = '2021'

  constructor(
    public dialogRef: MatDialogRef<QuickjobComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private DbserviceService: DbserviceService) { }


  ngOnInit() { 

    this.DbserviceService.read_Contratos('/contratos/FPblpv89ZrWuDpS6RfoI/servicos/L5hroHpQD8jM0tEJc7xF/instalacoes/').subscribe(data => {
      this.instalacoes = data.map(e => {
        return {     
          instalacao:   e.payload.doc.id.toString(),
                    
        };
      })
      console.log('payload: ' + this.instalacoes); 
           
    });     
     
  }  

  onClickQuickJob(): void {
    this.dialogRef.close({
      instalacao: this.instalacaoselected,
      baseMes: this.messelected,
      baseAno: this.anoselected,
      contrato: this.data.contrato,
      servico: this.data.id,
      provedor: this.data.provedor,
      cliente: this.data.cliente,
      nomeservico: this.data.nomeservico,
      robot: "download",
      status: "pendente",
      feito: false,
      credenciais: { "cnpj":"224", "codigocliente":this.data.clienteservico },
      fila: "fila-light",
      origem: 'job-avulso'
    });
  }


}