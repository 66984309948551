import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/storage';
import { environment } from '../../environments/environment';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { ServiceDataSource, ServiceItem } from './servicedetails-datasource';
import { DbserviceService } from '../services/dbservice.service';
import { MatIconModule} from '@angular/material/icon';
import { FormControl } from "@angular/forms";
import { ElementRef} from '@angular/core';
import { MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { ThemePalette} from '@angular/material/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Http } from '@angular/http';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { files } from '../tree/example-data';
//import { timeStamp } from 'console';


@Component({
  selector: 'app-servicedetails',
  templateUrl: './servicedetails.component.html',
  styleUrls: ['./servicedetails.component.scss'],
})
export class ServicedetailsComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<ServiceItem>;
  
  dataSource: ServiceDataSource;
  profileUrl: Observable<string | null>;
  
  servicedetails: any;
  dadoscontrato: any;
  contratoselected: any;
  nomecontrato = 'acao-engenharia';
  servicoselected = '';
  nomeservicoselected: any;
  //instalacaolista: string[] = ['0400034096', '0400034312', '0400062090', '0400082490', '0400090735', '0400116823', '0412802236'];
  instalacoes:any;
  instalacaoarray: string[];
  instalacaoselected = '';
  clienteselected = '';
  provedorselected = '';
  jobselected = '';
  displayedColumns = ['provedor', 'instalacao', 'baseMes', 'baseAno', 'robot', 'servico', 'jobID', 'entregue', 'execucao', 'arquivo'];
  ContratoSelecionado: string;
  isChecked = true;
  origemcontratos:boolean;
  origemjobs:boolean;
  origem:string;
  origeminstalacoes:boolean;
  


  meslista: string[] = ['01','02','03','04','05','06','07','08','09','10', '11','12'];
  messelected: string = '01';
  anolista: string[] = ['2026','2025','2024','2023','2022','2021','2020','2019'];
  anoselected: string = '2024';

  //select: FormControl = new FormControl(this.instalacaolista);

  constructor(private DbserviceService: DbserviceService,
              private route: ActivatedRoute,
              private _snackBar: MatSnackBar,
              private router: Router,
              private storage: AngularFireStorage,
              private storageRef: AngularFireStorage,
              private http: Http) {  }

  ngOnInit() { 
    this.dataSource = new ServiceDataSource();    
    this.dataSource.data = [];
    const parametros = this.route.snapshot.params;      // recebe os parâmetros da chamada enterior
    console.log('contrato',parametros.contrato);
    console.log('origem',parametros.origem);
    
    
    this.nomeservicoselected = parametros.nomeservico;
    this.clienteselected = parametros.cliente;
    this.provedorselected = parametros.provedor; 
    this.contratoselected = parametros.contrato;
    this.origem = parametros.origem;

    this.origemjobs = false;
    this.origemcontratos = false;
    this.origeminstalacoes = false;

    if (parametros.origem=='contratos') {
      this.origemcontratos = true;
      this.servicoselected = parametros.id;     
    }

    if (parametros.origem=='jobs') {
      this.origemjobs = true;
      this.jobselected = parametros.id;
      this.servicoselected = parametros.servico;    
    };

    if (parametros.origem=='instalacoes') {        
      this.origeminstalacoes = true;
      this.servicoselected = parametros.servico;
      this.instalacaoselected = parametros.instalacao;

      console.log('parametros recebidos', this.contratoselected, this.servicoselected, this.instalacaoselected)
    };

    console.log('busca instalacoes', '/contratos/' + this.contratoselected + '/servicos/'+ this.servicoselected+ '/instalacoes/');

     this.DbserviceService.read_Contratos('/contratos/' + this.contratoselected + '/servicos/'+ this.servicoselected+ '/instalacoes/').subscribe(data => {
      this.instalacoes = data.map(e => {
        return {     
          instalacao:   e.payload.doc.id,
          //dia:        e.payload.doc.get('dia'),
          
        };
      })
      //console.log('payload: ' + this.instalacoes);      
      //this.ObjetoparaArray(this.instalacoes);

      if (this.origemjobs){     

        console.log('db','/contratos/' + this.contratoselected + '/servicos/' + this.servicoselected +'/entrega/'+this.jobselected)
        
        this.DbserviceService.read_Contratos_Where('/contratos/' + this.contratoselected + '/servicos/' + this.servicoselected +'/entrega/',
        //'instalacao', '>=', this.instalacaoselected,
        //'baseMes', '==', this.messelected,              
        'jobID', '==', this.jobselected, 
        ).subscribe(data => {
        this.servicedetails = data.map(e => {
          return {     
            id:              e.payload.doc.id,
            instalacao:      e.payload.doc.get('instalacao'),
            servico:         e.payload.doc.get('servico'),
            provedor:        e.payload.doc.get('concessionaria'),
            //cliente:       e.payload.doc.get('cliente'),
            baseMes:         e.payload.doc.get('baseMes'),   
            entregue:        e.payload.doc.get('entregue'),   
            baseAno:         e.payload.doc.get('baseAno'),   
            robot:           e.payload.doc.get('job'),
            jobID:           e.payload.doc.get('jobID'),
            //arquivo:         environment.urlstorage + '/' + this.contratoselected + '/' + this.servicoselected + '/pdf' + e.payload.doc.get('arquivo'),
            arquivo:         this.contratoselected + '/' + this.servicoselected + '/pdf' + e.payload.doc.get('arquivo'),
            execucao:        e.payload.doc.get('timestampConclusao'),         
            
          };
        })
      this.dataSource.data = this.servicedetails;


     });
     //
    } else if (this.origemcontratos)
    {
      this.DbserviceService.read_Contratos_2Where('/contratos/' + this.contratoselected + '/servicos/' + this.servicoselected +'/entrega/',
      //'instalacao', '>=', this.instalacaoselected,
      'baseMes', '==', this.messelected,              
      'baseAno', '==', this.anoselected, 
      ).subscribe(data => {
      this.servicedetails = data.map(e => {
        return {     
          id:              e.payload.doc.id,
          instalacao:      e.payload.doc.get('instalacao'),
          servico:         e.payload.doc.get('servico'),
          provedor:        e.payload.doc.get('concessionaria'),
          //cliente:       e.payload.doc.get('cliente'),
          baseMes:         e.payload.doc.get('baseMes'),   
          baseAno:         e.payload.doc.get('baseAno'),   
          entregue:        e.payload.doc.get('entregue'),   
          robot:           e.payload.doc.get('job'),
          jobID:           e.payload.doc.get('jobID'),
          //arquivo:         environment.urlstorage + '/' + this.contratoselected + '/' + this.servicoselected + '/pdf' + e.payload.doc.get('arquivo'),
          arquivo:         this.contratoselected + '/' + this.servicoselected + '/pdf' + e.payload.doc.get('arquivo'),
          execucao:        e.payload.doc.get('timestampConclusao'),         
          
        };
      })
      this.dataSource.data = this.servicedetails;
      
      }); 
     // this.dataSource = new ServiceDataSource();       
    }

    else if (this.origeminstalacoes)
    {
      console.log('origem-instalacoes', this.contratoselected, this.servicoselected ,this.instalacaoselected)
      this.DbserviceService.read_Contratos_Where_Order('/contratos/' + this.contratoselected + '/servicos/' + this.servicoselected +'/entrega/',
      'instalacao', '==', this.instalacaoselected, 'timestampConclusao').subscribe(data => {
      this.servicedetails = data.map(e => {
        return {     
          id:              e.payload.doc.id,
          instalacao:      e.payload.doc.get('instalacao'),
          servico:         e.payload.doc.get('servico'),
          provedor:        e.payload.doc.get('concessionaria'),
          //cliente:       e.payload.doc.get('cliente'),
          baseMes:         e.payload.doc.get('baseMes'),   
          baseAno:         e.payload.doc.get('baseAno'),   
          entregue:        e.payload.doc.get('entregue'),   
          robot:           e.payload.doc.get('job'),
          jobID:           e.payload.doc.get('jobID'),
          //arquivo:         environment.urlstorage + '/' + this.contratoselected + '/' + this.servicoselected + '/pdf' + e.payload.doc.get('arquivo'),
          arquivo:         this.contratoselected + '/' + this.servicoselected + '/pdf' + e.payload.doc.get('arquivo'),
          execucao:        e.payload.doc.get('timestampConclusao'),         
          
        };
      })
      this.dataSource.data = this.servicedetails;
      
      }); 
     // this.dataSource = new ServiceDataSource();       
    }
     
    });       
      
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;   

    if (this.origemcontratos) {
    this.openSnackBar('Escolha o período para filtrar','Fechar');
    }
  }

  onContratoSelecionado() {
    console.log(this.ContratoSelecionado);

    this.DbserviceService.read_Contratos_2Where('/contratos/' + this.contratoselected + '/servicos/' + this.servicoselected +'/entrega/',
        // 'instalacao', '==', this.instalacaoselected,
         'baseMes', '==', this.messelected,              
         'baseAno', '==', this.anoselected,
         ).subscribe(data => {
      this.servicedetails = data.map(e => {
        return {     
          instalacao:      e.payload.doc.get('instalacao'),
          servico:         e.payload.doc.get('servico'),
          provedor:        e.payload.doc.get('concessionaria'),
          //cliente:       e.payload.doc.get('cliente'),
          baseMes:         e.payload.doc.get('baseMes'),   
          baseAno:         e.payload.doc.get('baseAno'),   
          entregue:        e.payload.doc.get('entregue'),   
          robot:           e.payload.doc.get('job'),
          jobID:           e.payload.doc.get('jobID'),
          //arquivo:         environment.urlstorage + '/' + this.contratoselected + '/' + this.servicoselected + '/pdf' + e.payload.doc.get('arquivo'),
          arquivo:         this.contratoselected + '/' + this.servicoselected + '/pdf' + e.payload.doc.get('arquivo'),
          execucao:        e.payload.doc.get('timestampConclusao'),
          
        };
      })
      //console.log('payload: ' + this.servicedetails);
      this.dataSource.data = this.servicedetails;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.table.dataSource = this.dataSource;
    
    });
      
   // this.dataSource = new ServiceDataSource(); 
    
  }

  selectedRow(row) {
      console.log(row);
  }

  openSnackBar(mensagem, saida) {
    this._snackBar.open(mensagem, saida, {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }


  IrPara(pagina) {
    
    this.router.navigate([pagina,this.route.snapshot.params] );;
  }


  ObjetoparaArray(obj) {
    var result = Object.keys(obj).map(function (key) {         
        // Using Number() to convert key to number type 
        // Using obj[key] to retrieve key value 
        return [obj[key].instalacao]; 
    }); 
     
    // Printing values 
    for(var i = 0; i < result.length; i++) { 
        
            console.log(result[i] + " "); 
        
    } 
  }
  async downloadPdf(nomearquivo) {

   
    console.log('nomearquivo',nomearquivo.arquivo)
    const fileRef = this.storage.ref(nomearquivo.arquivo);
    fileRef.getDownloadURL().subscribe(url => {
      console.log('url',url)

      window.open(url, "_blank");

  
    }) 

  }



}
