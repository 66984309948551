 
<div style="margin: 2rem;">
  <div>
    <h1> Clientes </h1>
   </div>
</div>

<div class="mat-elevation-z8">
  <table mat-table class="full-width-table" matSort aria-label="Elements">
    <!-- Id Column -->
    <ng-container matColumnDef="nome">
      <th mat-header-cell *matHeaderCellDef >Cliente</th>
      <td mat-cell *matCellDef="let row">{{row.nome}}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="cnpj">
      <th mat-header-cell *matHeaderCellDef >CNPJ</th>
      <td mat-cell *matCellDef="let row">{{row.cnpj}}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="razaosocial">
      <th mat-header-cell *matHeaderCellDef>Razão Social</th>
      <td mat-cell *matCellDef="let row">{{row.razaosocial}}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
      <td mat-cell *matCellDef="let row">{{row.id}}</td>
    </ng-container>

    <ng-container matColumnDef="acao">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" class="action-link"> 
        <button mat-stroked-button matTooltip="Documentos disponíveis" matTooltipClass="tooltip-blue" (click)="openClientesForm(element)" color="primary"><mat-icon>edit</mat-icon></button> &nbsp;        
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator #paginator
      [length]="dataSource?.data.length"
      [pageIndex]="0"
      [pageSize]="20"
      [pageSizeOptions]="[20, 40, 80, 100]">
  </mat-paginator>
</div>
