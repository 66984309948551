import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/storage';
import { environment } from '../../environments/environment';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { ServiceDataSource, ServiceItem } from './instalacoes-datasource';
import { DbserviceService } from '../services/dbservice.service';
import { MatIconModule} from '@angular/material/icon';
import { FormControl } from "@angular/forms";
import { ElementRef} from '@angular/core';
import { MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { ThemePalette} from '@angular/material/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Http } from '@angular/http';
import { AngularFirestore } from '@angular/fire/firestore';
import { InstalacoesModalComponent } from '../modals/instalacoes/instalacoes.component';
import { ConexoesModalComponent } from '../modals/conexoes/conexoes.component';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { files } from '../tree/example-data';
//import { timeStamp } from 'console';



@Component({
  selector: 'app-instalacoes',
  templateUrl: './instalacoes.component.html',
  styleUrls: ['./instalacoes.component.scss'],
})
export class InstalacoesComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<ServiceItem>;
  
  
  dataSource: ServiceDataSource;
  profileUrl: Observable<string | null>;
  
  servicedetails: any;
  dadoscontrato: any;
  contratoselected = 'FPblpv89ZrWuDpS6RfoI';
  nomecontrato = 'acao-engenharia';
  servicoselected = '';
  nomeservicoselected: any;  
  instalacoes:any;
  buscainstaslacoes:any;
  credenciais: any;
  instalacaoarray: string[];
  instalacaoselected = '';
  clienteselected = '';
  provedorselected = '';
  jobselected = '';
  displayedColumns = ['ativo','instalacao', 'cliente', 'nomeservico','clienteinstalacao', 'diaultimaleitura', 'inicioMes', 'inicioAno', 'conexaoID', 'acoes'];
  ContratoSelecionado: string;
  isChecked = true;
  origemcontratos:boolean;
  origemjobs:boolean;
  origem:string;
  origembuscainstalacao:boolean;
  instalacaoBusca:string; 
  servicoBusca:string;
  contratoBusca:string;
  nomeservicoBusca:string;
  provedorBusca:string;
  clienteBusca:string
  saidaInstalacao:any;
  buscaInstalacoes:any;
  
  

  


  meslista: string[] = ['01','02','03','04','05','06','07','08','09','10', '11','12'];
  messelected: string = '01';
  anolista: string[] = ['2021','2020','2019'];
  anoselected: string = '2021';

  //select: FormControl = new FormControl(this.instalacaolista);

  constructor(private DbserviceService: DbserviceService,
              private route: ActivatedRoute,
              private _snackBar: MatSnackBar,
              private router: Router,
              private storage: AngularFireStorage,
              private storageRef: AngularFireStorage,
              private http: Http,
              public dialog: MatDialog,
              private firestore: AngularFirestore) {  }

  ngOnInit() { 
    this.dataSource = new ServiceDataSource();    
    this.dataSource.data = [];
    const parametros = this.route.snapshot.params;      // recebe os parâmetros da chamada enterior
    
    console.log('origem',this.route.snapshot.queryParamMap.get('origem'));
    
    

    this.origemjobs = false;
    this.origemcontratos = false;
    this.origembuscainstalacao = false;

  //  if (parametros.contratoselected != undefined) {
      this.nomeservicoselected = parametros.nomeservico;
      this.clienteselected = parametros.cliente;
      this.provedorselected = parametros.provedor; 
      this.contratoselected = parametros.contrato;
      this.origem = parametros.origem;
   //   console.log('entrou 1')
  //  }
   // else {
   //   console.log('entrou 2')
      this.contratoselected = 'FPblpv89ZrWuDpS6RfoI';
   // }

    console.log('parametros',parametros.origem)

    if(parametros.origem=='contratos') { 
      this.origemcontratos = true;
      this.servicoselected = parametros.id;     
    };

    if( parametros.origem=='instalacoes') { //quando seleciona voltar, volta com mesmos parametros
      this.origemcontratos = true;
      this.servicoselected = parametros.servico;     
    };

    if(this.route.snapshot.queryParamMap.get('origem')=='buscainstalacao') {
      this.origembuscainstalacao = true;      
    };

      this.DbserviceService.read_Contratos('/contratos/' + this.contratoselected + '/servicos/'+ this.servicoselected+ '/instalacoes/').subscribe(data => {
      this.instalacoes = data.map(e => {
        return {     
          instalacao:         e.payload.doc.id,
          clienteinstalacao:  e.payload.doc.get('clienteinstalacao'),
          diaultimaleitura:   e.payload.doc.get('diaultimaleitura'),
          inicioMes:          e.payload.doc.get('inicioMes'),
          inicioAno:          e.payload.doc.get('inicioAno'),
          conexaoID:          e.payload.doc.get('conexaoID'),    
          ativo:              e.payload.doc.get('ativo'),    
          servico:            this.servicoselected,
          nomeservico:        this.nomeservicoselected,
          cliente:            this.clienteselected,
          provedor:           this.provedorselected,
          contrato:           this.contratoselected,
          origem:             'instalacoes'

          //dia:        e.payload.doc.get('dia'),
          //['instalacao', 'clienteinstalacao', 'diaultimaleitura', 'inicioMes', 'inicioAno', 'conexaoID']; 
        };
      })
      //console.log('payload: ' + this.instalacoes);      
      //this.ObjetoparaArray(this.instalacoes);

      this.dataSource.data = this.instalacoes;

     
    });       
      
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;   

    if (this.origemcontratos) {
      //this.openSnackBar('Escolha o período para filtrar','Fechar');
    }
  }

  selectedRow(row) {
      console.log(row);
  }

  openSnackBar(mensagem, saida) {
    this._snackBar.open(mensagem, saida, {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }


  IrPara(pagina) {
    this.router.navigate([pagina]);;
  }


  ObjetoparaArray(obj) {
    var result = Object.keys(obj).map(function (key) {         
        // Using Number() to convert key to number type 
        // Using obj[key] to retrieve key value 
        return [obj[key].instalacao]; 
    }); 
     
    // Printing values 
    for(var i = 0; i < result.length; i++) { 
        
            console.log(result[i] + " "); 
        
    } 
  }


  openModalInstalacoes(row): void {

    const dialogRef = this.dialog.open(InstalacoesModalComponent, {
      width: '600px',
      height: '550px',
      data: row
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if(result.event =='Atualizar'){                               // se o job avulso for confirmado, adiciona registro e roteia para tela de jobs
        console.log('Entrou em Atualizar')
        this.DbserviceService.update_DB('contratos/'+ result.data.contrato +'/servicos/'+result.data.servico+'/instalacoes', 
        {clienteinstalacao: result.data.clienteinstalacao, 
         diaultimaleitura: result.data.diaultimaleitura,
         conexaoID: result.data.conexaoID,
         inicioAno: result.data.inicioAno,
         inicioMes: result.data.inicioMes,
         ativo: result.data.ativo,
        }, result.data.instalacao);
        
        this.openSnackBar('Dados atualizados','Fechar');
      //  this.router.navigate(['jobs' ]);
      };
    });
  }
  async openModalConexoes(row)  {  // busca conexao


    var docRef = this.firestore.collection('/contratos/' + row.contrato + '/servicos/'+ row.servico+ '/instalacoes/').doc(row.instalacao);          
    
    docRef.get()
    .toPromise()
    .then(function(doc) {
      
      if (doc.exists) {            
          row.conexaoID=          doc.data().conexaoID            
      } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
      }
    })
    
    
    
    .then(() => { // busca provedor

      var docRef = this.firestore.collection('/contratos/' + row.contrato + '/servicos/').doc(row.servico);            

      docRef.get()
      .toPromise()
      .then(function(doc) {
        if (doc.exists) {
                   
          
                   
            if (doc.data().provedor != undefined) {
                row.provedor = doc.data().provedor                    
                console.log('provedor',row.provedor)
            }
            else  
                row.provedor = null;                    
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
      
      })

      .then(() => { // busca url
        console.log('provedor-2',row.provedor)
        var docRef = this.firestore.collection('provedores').doc(row.provedor);            
  
        docRef.get()
        .toPromise()
        .then(function(doc) {
          if (doc.exists) {
                     
                     
              if (doc.data().url != undefined) {
                  row.url = doc.data().url                    
                  console.log("Entrou URL", row.servico, row.url);
              }
              else  
                  row.url = null;                    
          } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
          }
        
        })
      })

            
    .then(() =>{        // busca instalacao     
      
      var docRef = this.firestore.collection('/contratos/' + row.contrato + '/servicos/'+ row.servico+ '/conexoes/').doc(row.conexaoID);            
  
      docRef.get()
      .toPromise()
      .then(function(doc) {
        if (doc.exists) {
            //console.log("Document data:", doc.data());

            row.nomeconexao = doc.data().nomeconexao;
            row.senha = doc.data().senha;
            
            if (doc.data().usuario != undefined)
                row.usuario = doc.data().usuario
            else  
                row.usuario = null;
            

            if (doc.data().codigocliente != undefined)
                row.clienteacesso = doc.data().codigocliente
            else  
                row.clienteacesso = null;
            
              } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
      })


      .then(() =>{           // carrega modal conexoes

        //console.log('row',row)

          const dialogRef = this.dialog.open(ConexoesModalComponent, {
            width: '600px',
            height: '550px',
            data: row
          });
      
          dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed', result);
            if(result.event =='Atualizar'){                               // se o job avulso for confirmado, adiciona registro e roteia para tela de jobs
              console.log('Entrou em Atualizar')
              this.DbserviceService.update_DB('contratos/'+ result.data.contrato +'/servicos/'+result.data.servico+'/conexoes', 
              { usuario: result.data.usuario,
                senha: result.data.senha,                
              }, result.data.conexaoID);
              
              this.openSnackBar('Dados atualizados','Fechar');
            //  this.router.navigate(['jobs' ]);
            };
          });
        })
        
        .catch(function(error) {
          console.log("Error getting document:", error);
        });   
      })
  
      
    })

    }

    openServicosDetalhes(row) {
      console.log(row);
      this.router.navigate(['servicedetails', row ]);
    }
    
    
    buscaInstalacao(instalacao) {
      
  
      console.log('busca',this.instalacaoBusca , this.contratoselected)
      var docRef = this.firestore.collection('/contratos/' + this.contratoselected + '/instalacoes/').doc(this.instalacaoBusca );          
    
      docRef.get()
      .toPromise()
      .then((doc) => {
        
        if (doc.exists) {            
           this.servicoBusca = doc.data().servico   
           this.nomeservicoBusca = doc.data().nomeservico 
           this.clienteBusca = doc.data().cliente
           this.provedorBusca = doc.data().provedor            
  
        } else {
            // doc.data() will be undefined in this case            
            console.log("No such document!");
            this.openSnackBar('Instalação não encontrada!','Fechar');
            //  this.router.navigate(['jobs' ]);
            return
        }
      })
      .then((servicoBusca)  => {
             console.log('buscainstalacao', '/contratos/' + this.contratoselected+ '/servicos/'+ this.servicoBusca + '/instalacoes/',this.instalacaoBusca)

            
            
            var docRef = this.firestore.collection('/contratos/' + this.contratoselected + '/servicos/'+ this.servicoBusca  + '/instalacoes/').doc(this.instalacaoBusca);            
        
            docRef.get()
            .toPromise()
            .then((doc) => {
              if (doc.exists) {
                  
                    this.buscaInstalacoes =  [  {   
                        instalacao:         doc.id,
                        clienteinstalacao:  doc.data().clienteinstalacao,
                        diaultimaleitura:   doc.data().diaultimaleitura,
                        inicioMes:          doc.data().inicioMes,
                        inicioAno:          doc.data().inicioAno,
                        conexaoID:          doc.data().conexaoID,    
                        ativo:              doc.data().ativo,     
                        servico:            this.servicoBusca,
                        nomeservico:        this.nomeservicoBusca,
                        cliente:            this.clienteBusca,
                        provedor:           this.provedorBusca,
                        contrato:           this.contratoselected,
                        origem:             'instalacoes'
              
                    }];
                    
                    //console.log("Document data:", this.buscainstalacoes);

                    this.dataSource.data = this.buscaInstalacoes;
            
        
                  
                    } else {
                  // doc.data() will be undefined in this case
                  console.log("No such document!");
              }
            })

      })      
      
    }
}
