import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { FormArray } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { DbserviceService } from '../../../services/dbservice.service';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

@Component({
  selector: 'app-provedores-form',
  templateUrl: './provedoresform.component.html',
  styleUrls: ['./provedoresform.component.scss']
})
export class ProvedoresFormComponent implements OnInit {
  profileForm = this.fb.group({
    id: [{value: '', disabled: true}],
    tipo: [''],
    servico: [''],
    nome: [''],
    alias: [''],
    url: [''],
    
  });
  acao:any;




  get aliases() {
    return this.profileForm.get('aliases') as FormArray;
  }

  constructor(private fb: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private DbserviceService: DbserviceService,
              private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
   
    const parametros = this.route.snapshot.params;      // recebe os parâmetros da chamada enterior
    this.acao = parametros.acao;
    this.profileForm.patchValue(this.route.snapshot.params);
    if (parametros.acao == "new")
      this.profileForm.controls['id'].enable();    

  }

  updateProvedor(payload,id) {

    if (this.route.snapshot.params.acao == "edit")    // se estiver e modo de edição, utiliza a mesma chave recebida
      id = this.route.snapshot.params.id;  

    this.DbserviceService.update_DB('provedores',payload,id);
    this.openSnackBar('Dados atualizados','Fechar');
    this.router.navigate(['provedores'])
  }

  deleteProvedor() {
    let id = this.route.snapshot.params.id;  
    this.DbserviceService.delete_DB('provedores',id);
    this.openSnackBar('Cadastro excluído','Fechar');
    this.router.navigate(['provedores'])
  }


  voltarProvedor() {
    this.router.navigate(['provedores'])
  }

  


  onSubmit() {
    // TODO: Use EventEmitter with form value
    console.warn(this.profileForm.value);
  }


  openSnackBar(mensagem, saida) {
    this._snackBar.open(mensagem, saida, {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }
}


