<h1 mat-dialog-title>Dados para Acesso</h1>

<div mat-dialog-content>
    
    <p>Informe os dados para acesso ao site do provedor:</p>
    
    <div class="row">
            
        <div class="col">
            <mat-form-field>
                <mat-label>Serviço</mat-label>
                <input disabled='true' matInput [(ngModel)]="data.nomeservico">
            </mat-form-field>
        </div>

        <div class="col">
            <mat-form-field>
                <mat-label>ID do serviço</mat-label>
                <input disabled='true' matInput [(ngModel)]="data.id">
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <mat-form-field>
                <mat-label>Cliente</mat-label>
                <input disabled='true' matInput [(ngModel)]="data.cliente">
            </mat-form-field>
        </div>
    
        <div class="col">
            <mat-form-field>
                <mat-label>Provedor</mat-label>
                <input disabled='true' matInput [(ngModel)]="data.provedor">
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <mat-form-field>
                <mat-label>Mês Inicial</mat-label>
                <mat-select disabled='true' [(ngModel)]="data.inicioMes" > 
                <mat-option *ngFor="let opcao of meslista" [value]="opcao">
                    {{opcao}}
                </mat-option>
                </mat-select>
             </mat-form-field>
        </div>

        <div class="col">
            <mat-form-field>
                <mat-label>Ano Inicial</mat-label>
                <mat-select disabled='true' [(ngModel)]="data.inicioAno" > 
                <mat-option *ngFor="let opcao of anolista" [value]="opcao">
                    {{opcao}}
                </mat-option>
                </mat-select>
             </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col">
                <mat-form-field>
                <mat-label>CNPJ (3 primeiros dígitos)</mat-label>
                <input matInput [(ngModel)]="data.cnpj">
            </mat-form-field>
        </div>

        <div class="col">
            <mat-form-field>
            <mat-label>Cliente serviço</mat-label>
            <input matInput [(ngModel)]="data.clienteservico">
        </mat-form-field>
    </div>

    </div>

    <div class="row">
        <div class="col">
                <mat-form-field>
                <mat-label>Usuário</mat-label>
                <input disabled='true' matInput value = "***************">
            </mat-form-field>
        </div>

        <div class="col">
            <mat-form-field>
            <mat-label>Senha</mat-label>
            <input disabled='true' matInput value = "***************">
        </mat-form-field>
    </div>

    </div>



</div>

<div mat-dialog-actions>
    <button mat-stroked-button (click)="onClickDadosAcesso()" color="accent">Processar</button>
    
</div>