import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { DbserviceService } from '../../services/dbservice.service';

export interface UsersData {
  instalacao: string;
}

const ELEMENT_DATA: UsersData[] = [
  {instalacao: '0400034096'},
  {instalacao: '0400034312'},
  {instalacao: '0400062090'},
  {instalacao: '0400082490'},
  {instalacao: '0400090735'},
  {instalacao: '0400116823'},

];


@Component({
  selector: 'app-conexoes',
  templateUrl: './conexoes.component.html',
  styleUrls: ['./conexoes.component.scss']
})

export class ConexoesModalComponent {
  
  instalacoes: any;
  dataSource: any;
  food: string;
  credenciais: any;
  instalacaolista: string[] = ['0400034096', '0400034312', '0400062090', '0400082490', '0400090735', '0400116823'];
  instalacaoselected: string = '0400034096';
  meslista: string[] = ['01','02','03','04','05','06','07','08','09','10', '11','12'];
  messelected: string = '01';
  anolista: string[] = ['2021','2020','2019'];
  anoselected: string = '2021';
  typesOfShoes: string[] = ['Boots', 'Clogs', 'Loafers', 'Moccasins', 'Sneakers', 'Loafers', 'Moccasins', 'Sneakers'];
  local_data:any;
  displayedColumns: string[] = [ 'instalacao', 'action']; 
  //dataSource = ELEMENT_DATA;
  hide = true;

  @ViewChild(MatTable,{static:true}) table: MatTable<any>;

  constructor(
    public dialogRef: MatDialogRef<ConexoesModalComponent>,  
    @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog, private DbserviceService: DbserviceService) { 
      this.local_data = {...data};
    }


  ngOnInit() { 

    //console.log('local_data', this.local_data)
    
  }  



  doAction(acao){
    this.dialogRef.close({event: acao, data:this.local_data});
  }


  goToLink(url: string){
    window.open(url, "_blank");
}




}