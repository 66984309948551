import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component'; // Add this
import { TabelaComponent } from './tabela/tabela.component'; // Add this
import { DashboardComponent } from './dashboard/dashboard.component'; // Add this
import { TreeComponent} from './tree/tree.component';
import { ContratosComponent} from './contratos/contratos.component';
import { OcrJobsComponent} from './ocrjobs/ocrjobs.component';
import { JobsComponent} from './jobs/jobs.component';
import { VerificacaoComponent} from './verificacao/verificacao.component';
import { ProcessamentoComponent} from './processamento/processamento.component';
import { ServicedetailsComponent} from './servicedetails/servicedetails.component'
import { InstalacoesComponent} from './instalacoes/instalacoes.component'
import { OcrJobsdetailsComponent} from './ocrjobsdetails/ocrjobsdetails.component'
import { StatusComponent} from './status/status.component'
import { AddressComponent} from './address/address.component';
import { DashboardUserComponent } from './dashboard-user/dashboard-user.component';
import { LoginComponent } from './login/login.component';
import { ClientesTableComponent } from './cadastros/clientes/table/clientestable.component';
import { ClientesFormComponent } from './cadastros/clientes/form/clientesform.component';
import { ProvedoresTableComponent } from './cadastros/provedores/table/provedorestable.component';
import { ProvedoresFormComponent } from './cadastros/provedores/form/provedoresform.component';
import { MyGuard } from './my.guard';


const routes: Routes = [
  
  { path: '',  loadChildren: () => import('./login/login.module').then(m => m.MainModule)},
  { path: 'login',  loadChildren: () => import('./login/login.module').then(m => m.MainModule)},
  { path: 'dashboard', canActivate: [MyGuard], component: DashboardComponent },  
  { path: 'home', canActivate: [MyGuard], component: HomeComponent },              // Add this
  { path: 'contratos', canActivate: [MyGuard], component: ContratosComponent },              // Add this
  { path: 'ocrjobs', canActivate: [MyGuard], component: OcrJobsComponent },              // Add this
  { path: 'verificacao', canActivate: [MyGuard], component: VerificacaoComponent },              // Add this
  { path: 'processamento', canActivate: [MyGuard], component: ProcessamentoComponent },              // Add this
  { path: 'jobs', canActivate: [MyGuard], component: JobsComponent },              // Add this
  { path: 'servicedetails', canActivate: [MyGuard], component: ServicedetailsComponent },              // Add this
  { path: 'instalacoes', canActivate: [MyGuard], component: InstalacoesComponent },              // Add this
  { path: 'ocrjobsdetails', canActivate: [MyGuard], component: OcrJobsdetailsComponent },              // Add this
  { path: 'status', canActivate: [MyGuard], component: StatusComponent },              // Add this
  { path: 'tabela', canActivate: [MyGuard], component: TabelaComponent },              // Add this
  { path: 'dashboard', canActivate: [MyGuard], component: DashboardComponent },              // Add this
  { path: 'dashboard-user', canActivate: [MyGuard], component: DashboardUserComponent },              // Add this
  { path: 'tree', canActivate: [MyGuard], component: TreeComponent },              // Add this
  { path: 'address', canActivate: [MyGuard], component: AddressComponent },              // Add this
  { path: 'clientes', canActivate: [MyGuard], component: ClientesTableComponent },              // Add this
  { path: 'clientesForm', canActivate: [MyGuard], component: ClientesFormComponent },              // Add this
  { path: 'provedores', canActivate: [MyGuard], component: ProvedoresTableComponent },              // Add this
  { path: 'provedoresForm', canActivate: [MyGuard], component: ProvedoresFormComponent },              // Add this


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
