import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../environments/environment';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { TabelaDataSource, TabelaItem } from './tabela-datasource';
import { DbserviceService } from '../services/dbservice.service';
import { MatIconModule} from '@angular/material/icon';
import { FormControl } from "@angular/forms";
import { ElementRef} from '@angular/core';

@Component({
  selector: 'app-tabela',
  templateUrl: './tabela.component.html',
  styleUrls: ['./tabela.component.scss'],
})
export class TabelaComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<TabelaItem>;
  dataSource: TabelaDataSource;
  invoices: any;
  concessionarialista: string[] = ['Light'];
  concessionariaselected = 'Light';
  clientelista: string[] = ['itau-0020000390'];
  clienteselected = 'itau-0020000390';
  instalacaolista: string[] = ['400034096', '400034312', '400062090', '400082490', '400090735', '400116823'];
  displayedColumns = ['cliente', 'instalacao', 'mesano','notafiscal', 'leitant', 'leitatu', 'dataemissao', 'datavencimento', 'ilumpublica', 'icms', 'valor', 'pdf', 'json'];
  InstalacaoSelecionada: string;

  //select: FormControl = new FormControl(this.instalacaolista);

  constructor(private DbserviceService: DbserviceService) { }

  ngOnInit() {
  
    
    this.DbserviceService.read_Invoices('/processos/concluidos/light/itau-0020000390/400082490').subscribe(data => {
      this.invoices = data.map(e => {
        return {     
          cliente: e.payload.doc.data()['cliente'],
          instalacao: e.payload.doc.data()['instalacao'],
          mesano: e.payload.doc.data()['mesano'],
          notafiscal: e.payload.doc.data()['notafiscal'],
          leitant: e.payload.doc.data()['leitant'],
          leitatu: e.payload.doc.data()['leitatu'],
          valor: e.payload.doc.data()['valor'],
          dataemissao: e.payload.doc.data()['dataemissao'],
          datavencimento: e.payload.doc.data()['datavencimento'],
          ilumpublica: e.payload.doc.data()['ilumpublica'],
          icms: e.payload.doc.data()['icms'],
          pdf: environment.urlstorage + '/pdf/' + e.payload.doc.data()['filename'],
          json: environment.urlstorage + '/json/' + e.payload.doc.data()['filename']+'.json',
          
        };
      })
      console.log('payload: ' + this.invoices);
      this.dataSource.data = this.invoices;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.table.dataSource = this.dataSource;
      
    });
    
    this.dataSource = new TabelaDataSource(); 
    
  }

  ngAfterViewInit() {
  }

  onInstalacaoSelecionada() {
    console.log(this.InstalacaoSelecionada);

    this.DbserviceService.read_Invoices('processos/concluidos/light/itau-0020000390/'+this.InstalacaoSelecionada).subscribe(data => {
      this.invoices = data.map(e => {
        return {     
          cliente: e.payload.doc.data()['cliente'],
          instalacao: e.payload.doc.data()['instalacao'],
          mesano: e.payload.doc.data()['mesano'],
          notafiscal: e.payload.doc.data()['notafiscal'],
          leitant: e.payload.doc.data()['leitant'],
          leitatu: e.payload.doc.data()['leitatu'],
          valor: e.payload.doc.data()['valor'],
          dataemissao: e.payload.doc.data()['dataemissao'],
          datavencimento: e.payload.doc.data()['datavencimento'],
          ilumpublica: e.payload.doc.data()['ilumpublica'],
          icms: e.payload.doc.data()['icms'],
          pdf: environment.urlstorage + '/pdf/' + e.payload.doc.data()['filename'],
          json: environment.urlstorage + '/json/' + e.payload.doc.data()['filename']+'.json',
          
        };
      })
      console.log('payload: ' + this.invoices);
      this.dataSource.data = this.invoices;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.table.dataSource = this.dataSource;
    
    });
      
  this.dataSource = new TabelaDataSource(); 
  
  }

  
}
