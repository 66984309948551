 
<div style="margin: 2rem;">
  <div>
    <h1> Provedores </h1>
   </div>
</div>

<div style="margin: 1rem;">

  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
    <mat-icon>more_vert</mat-icon> Opções
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="newProvedoresForm()">
      <mat-icon>add</mat-icon>
      <span>Novo item</span>
    </button>
    <button mat-menu-item (click)="exporter.exportTable('xlsx', {fileName:'export', sheet: 'r3pa', Props: {Author: 'r3pa'}})">
      <mat-icon>file_download</mat-icon>
      <span>Exportar para o Excel</span>
    </button>
  </mat-menu>
</div>

<div class="mat-elevation-z8">
  <table mat-table matTableExporter class="full-width-table" #exporter="matTableExporter" matSort aria-label="Elements">

    <!-- Name Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
      <td mat-cell *matCellDef="let row">{{row.id}}</td>
    </ng-container>
    
    <!-- Id Column -->
    <ng-container matColumnDef="nome" >
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Provedor</th>
      <td mat-cell *matCellDef="let row">{{row.nome}}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="alias">
      <th mat-header-cell *matHeaderCellDef >Abreviacao</th>
      <td mat-cell *matCellDef="let row">{{row.alias}}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="servico">
      <th mat-header-cell *matHeaderCellDef>Servico</th>
      <td mat-cell *matCellDef="let row">{{row.servico}}</td>
    </ng-container>



    <!-- Name Column -->
    <ng-container matColumnDef="tipo">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo</th>
      <td mat-cell *matCellDef="let row">{{row.tipo}}</td>
    </ng-container>

    <ng-container matColumnDef="acao">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" class="action-link"> 
        <button mat-stroked-button matTooltip="Editar" matTooltipClass="tooltip-blue" (click)="openProvedoresForm(element,'edit')" color="primary"><mat-icon>edit</mat-icon></button> &nbsp;        
        <button mat-stroked-button matTooltip="Excluir" matTooltipClass="tooltip-blue" (click)="openProvedoresForm(element,'delete')" color="warn"><mat-icon>delete</mat-icon></button> &nbsp;        
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator #paginator
      [length]="dataSource?.data.length"
      [pageIndex]="0"
      [pageSize]="20"
      [pageSizeOptions]="[20, 40, 80, 100]">
  </mat-paginator>
</div>
