<div style="margin: 2rem;">
  <div class="botao-voltar">
     <button mat-stroked-button matTooltip="Voltar" matTooltipClass="tooltip-blue" (click)="IrPara(origem)" color="primary" ><mat-icon>arrow_back</mat-icon></button> &nbsp;
  </div>
  <div>
    <h1> Documentos </h1>
  </div>
</div>

<div style="margin: 2rem;">
    
  <mat-form-field>
      <mat-label>Contrato</mat-label>
      <input disabled='true' matInput [(ngModel)]="nomecontrato">
  </mat-form-field>

  <mat-form-field  *ngIf="!origemjobs">
    <mat-label>Serviço</mat-label>
      <input disabled='true' matInput [(ngModel)]="nomeservicoselected">
  </mat-form-field>

  <mat-form-field  *ngIf="!origemjobs" appearance="legacy">
    <mat-label>Provedor</mat-label>
    <input  disabled='true' matInput [(ngModel)]="provedorselected">
   </mat-form-field>

   <mat-form-field  *ngIf="!origemjobs" appearance="legacy">
    <mat-label>Cliente</mat-label>
    <input disabled='true' matInput [(ngModel)]="clienteselected">
   </mat-form-field>


  <mat-form-field *ngIf="origemcontratos">
      <mat-label>Mês</mat-label>
      <mat-select [(ngModel)]="messelected" (ngModelChange)="onContratoSelecionado()"> 
      <mat-option *ngFor="let opcao of meslista" [value]="opcao">
          {{opcao}}
      </mat-option>
      </mat-select>
    </mat-form-field>

  <mat-form-field *ngIf="origemcontratos">
      <mat-label>Ano</mat-label>
      <mat-select [(ngModel)]="anoselected" (ngModelChange)="onContratoSelecionado()"> 
      <mat-option *ngFor="let opcao of anolista" [value]="opcao">
          {{opcao}}
      </mat-option>
      </mat-select>
   </mat-form-field>

   <mat-form-field *ngIf="origemjobs">
      <mat-label>Job</mat-label>
      <input disabled='true' matInput [(ngModel)]="jobselected">
    </mat-form-field>


   <!-- Esperando analise do filtro de instalacao
    <mat-form-field >
      <mat-label >Escolha uma instalação</mat-label>
      <mat-select  [(ngModel)]="instalacaoselected" (ngModelChange)="onContratoSelecionado()"> 
      <mat-option  *ngFor="let item of instalacoes | keyvalue" [value]="item.value.instalacao">
          {{item.value.instalacao}}                
      </mat-option>
    </mat-select>    
  </mat-form-field>-->

  
</div>
  
  <div class="mat-elevation-z8">
    <table [dataSource]="dataSource.data" mat-table class="full-width-table" matSort aria-label="Elements">
      
      <!-- Id Column -->
      <ng-container matColumnDef="instalacao">
        <th mat-header-cell *matHeaderCellDef >Instalação</th>
        <td mat-cell *matCellDef="let row">{{row.instalacao}}</td>
      </ng-container>
  
      <!-- Id Column -->
      <ng-container matColumnDef="servico">
        <th mat-header-cell *matHeaderCellDef >Id do Servico</th>
        <td mat-cell *matCellDef="let row">{{row.servico}}</td>
      </ng-container>
  
      <!-- Name Column -->
      <ng-container matColumnDef="provedor">
        <th mat-header-cell *matHeaderCellDef >Provedor</th>
        <td mat-cell *matCellDef="let row">{{row.provedor}}</td>
      </ng-container>
  
      <!-- Name Column -->
      <ng-container matColumnDef="baseMes">
        <th mat-header-cell *matHeaderCellDef >Mês</th>
        <td mat-cell *matCellDef="let row">{{row.baseMes}}</td>
      </ng-container>

        
      <!-- Name Column -->
      <ng-container matColumnDef="baseAno">
        <th mat-header-cell *matHeaderCellDef >Ano</th>
        <td mat-cell *matCellDef="let row">{{row.baseAno}}</td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="robot">
        <th mat-header-cell *matHeaderCellDef >Robô</th>
        <td mat-cell *matCellDef="let row">{{row.robot}}</td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="jobID">
        <th mat-header-cell *matHeaderCellDef >JobID</th>
        <td mat-cell *matCellDef="let row">{{row.jobID}}</td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="entregue">
        <th mat-header-cell *matHeaderCellDef >Entregue</th>
        <td mat-cell *matCellDef="let row"><mat-icon> {{row.entregue == true ? "done" : "remove" }}</mat-icon></td>      
      </ng-container>


       <!-- Name Column 
       <ng-container matColumnDef="arquivo">
        <th  mat-header-cell *matHeaderCellDef  >Pdf</th>
        <td  mat-cell *matCellDef="let row"><a href={{row.arquivo}} target="_blank"><mat-icon>picture_as_pdf</mat-icon></a></td>
       </ng-container>  -->

      <!-- Name Column  -->
      <ng-container matColumnDef="arquivo">
        <th  mat-header-cell *matHeaderCellDef>Pdf</th>
        <td mat-cell *matCellDef="let element" class="action-link"> 
        <button mat-stroked-button  matTooltipClass="tooltip-blue"(click)="downloadPdf(element)" color="primary"><mat-icon>picture_as_pdf</mat-icon></button> &nbsp;        
      </ng-container>
         
  
      <!-- Name Column -->
      <ng-container matColumnDef="execucao">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Execução</th>
        <td mat-cell *matCellDef="let row">{{row.execucao == null ? "" : row.execucao.seconds*1000 | date:'dd/MM/yyyy | hh:mm a' }}</td>
      </ng-container>
     

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectedRow(row)"></tr>
    </table>
            <mat-paginator #paginator
        [length]="dataSource?.data.length"
        [pageIndex]="0"
        [pageSize]="20"
        [pageSizeOptions]="[20, 40, 80, 100]">
    </mat-paginator>


</div>
  
<div>
  &nbsp;
</div>


  