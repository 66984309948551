<h1 mat-dialog-title>Job avulso</h1>

<div mat-dialog-content>
    
    <p>Informe os dados para execução:</p>
    
    <div class="row">
            
        <div class="col">
            <mat-form-field>
                <mat-label>Provedor</mat-label>
                <input disabled='true' matInput [(ngModel)]="data.provedor">
            </mat-form-field>
        </div>

        <div class="col">
            <mat-form-field>
                <mat-label>Cliente</mat-label>
                <input disabled='true' matInput [(ngModel)]="data.cliente">
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <mat-form-field>
                <mat-label>Servico</mat-label>
                <input disabled='true' matInput [(ngModel)]="data.id">
            </mat-form-field>
        </div>
    
        <div class="col">
            <mat-form-field>
                <mat-label>Robô dowload</mat-label>
                <input disabled='true' matInput [(ngModel)]="data.download">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col">
                <mat-form-field>
                <mat-label>Cliente serviço</mat-label>
                <input disabled='true' matInput [(ngModel)]="data.clienteservico">
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field>
                <mat-label>Instalação</mat-label>
                <mat-select [(ngModel)]="instalacaoselected" > 
                <mat-option *ngFor="let item of instalacoes | keyvalue" [value]="item.value.instalacao">
                    {{item.value.instalacao}}                
                </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

    </div>


    <div class="row">
        <div class="col">
            <mat-form-field>
                <mat-label>Mês</mat-label>
                <mat-select [(ngModel)]="messelected" > 
                <mat-option *ngFor="let opcao of meslista" [value]="opcao">
                    {{opcao}}
                </mat-option>
                </mat-select>
             </mat-form-field>
        </div>

        <div class="col">
            <mat-form-field>
                <mat-label>Ano</mat-label>
                <mat-select [(ngModel)]="anoselected" > 
                <mat-option *ngFor="let opcao of anolista" [value]="opcao">
                    {{opcao}}
                </mat-option>
                </mat-select>
             </mat-form-field>
        </div>
    </div>

</div>

<div mat-dialog-actions>
    <button mat-stroked-button (click)="onClickQuickJob()" color="accent">Processar</button>
    
</div>