
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Total } from '../../models/total';
import { environment } from '../../../environments/environment';
import { FormControl, Validators, FormGroup} from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule} from '@angular/common';

@Component({
  selector: 'app-resumo',
  templateUrl: './resumo.component.html',
  styleUrls: ['./resumo.component.scss']
})

export class ResumoComponent {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  }


  resumoForm = new FormGroup({
    messelected: new FormControl('', [Validators.required]),
    anoselected: new FormControl('', [Validators.required])
  });

  
  url:string;
  food: string;
  instalacaolista: string[] = ['0400034096', '0400034312', '0400062090', '0400082490', '0400090735', '0400116823'];
  instalacaoselected: string = '0400034096';
  meslista: string[] = ['01','02','03','04','05','06','07','08','09','10', '11','12']
  messelected: string;
  anolista: string[] = ['2021','2020','2019'];
  anoselected: string = '2021'
  instalacoesTotal = 0;
  instalacoesBaixadas = 0;
  instalacoesEntregues = 0;
  instalacoesTimeout = 0;
  instalacoesVerificar = 0;
  instalacoesIncompletos = 0;
  percentualTotal = 0;
  percentualCorretas = 0; 
  botaoCalcularVisivel = true;
  botaoSairVisivel = true;
  CalcularDesabilitado = false;
  spinVisivel = false;
  botaoAcao = "Calcular";

  total = {} as Total;

  


  constructor(
    public dialogRef: MatDialogRef<ResumoComponent>,
    private httpClient: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: any) { }



  onClickResumo(): void {
    this.botaoAcao = "Aguarde processando...";
    this.spinVisivel = true;
    this.botaoSairVisivel = false;
    
    this.url = environment.urlCloudFunction + '/resumo/' + this.data.contrato + '/' + this.data.id + '/' + this.anoselected +'/' + this.messelected   ; // api rest resumo

    console.log('url:',this.url);

    this.getTotais().subscribe((total: Total) => {
      this.total = total;
      this.instalacoesTotal = this.total.totalInstalacoes;
      this.instalacoesBaixadas = this.total.totalBaixadas;
      this.instalacoesEntregues = this.total.totalEntregues;
      this.instalacoesTimeout = this.total.totalTimeout;
      this.instalacoesVerificar = this.total.totalVerificar;
      this.instalacoesIncompletos = this.total.totalInstalacoes - this.total.totalBaixadas - this.total.totalVerificar - this.total.totalTimeout;    // total de pendentes
      this.percentualTotal =  (this.instalacoesEntregues / this.instalacoesTotal) * 100
      this.percentualCorretas =  (this.instalacoesEntregues / (this.instalacoesTotal - this.total.totalVerificar)) * 100

      this.botaoAcao = "Finalizado";
      this.CalcularDesabilitado = true;
      this.botaoSairVisivel = true;
      this.spinVisivel = false;

    });
    
   
    /*    this.dialogRef.close({
      servico: this.data.id,
      contrato: this.data.contrato,
      atualizacao: { cnpj: this.data.cnpj, clienteservico: this.data.clienteservico},        

    });*/
  }


  onClickClose(): void{
     this.dialogRef.close();
  }

  // Obtem todos os carros
  getTotais(): Observable<Total> {
    return this.httpClient.get<Total>(this.url)
      .pipe(
        retry(2),
        catchError(this.handleError))
  }

  // Manipulação de erros
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Erro ocorreu no lado do client
      errorMessage = error.error.message;
    } else {
      // Erro ocorreu no lado do servidor
      errorMessage = `Código do erro: ${error.status}, ` + `menssagem: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
};
}