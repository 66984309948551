<h1 mat-dialog-title>Comentarios</h1>


<div mat-dialog-content class="col">
    
    <p>Adicione os comentários após verificação:</p>
    
               
        
            <mat-form-field class="full-width"> 
                <mat-label>Comentário</mat-label>
                <textarea matInput [(ngModel)]="data.comentario"></textarea>
            </mat-form-field>
        
        

    

</div>

<div mat-dialog-actions>
    <div>
        <button  mat-raised-button (click)="onClickGravar()" color="primary">Gravar</button>
        <button *ngIf="this.botaoSairVisivel" mat-stroked-button (click)="onClickClose()" color="primary">Sair</button>
    </div>
    <div style="margin: 1rem;">
        <mat-spinner *ngIf="this.spinVisivel"  diameter="20" value="100" mode="indeterminate"></mat-spinner>
    </div>
  
    
</div>