<h1 mat-dialog-title>Resumo</h1>


<div mat-dialog-content>
    
    <p>Escolha os parâmetros desejados:</p>
    
    <div class="row">
            
        <div class="col">
            <mat-form-field>
                <mat-label>Serviço</mat-label>
                <input disabled='true' matInput [(ngModel)]="data.nomeservico">
            </mat-form-field>
        </div>

        <div class="col">
            <mat-form-field>
                <mat-label>ID do serviço</mat-label>
                <input disabled='true' matInput [(ngModel)]="data.id">
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <mat-form-field>
                <mat-label>Cliente</mat-label>
                <input disabled='true' matInput [(ngModel)]="data.cliente">
            </mat-form-field>
        </div>
    
        <div class="col">
            <mat-form-field>
                <mat-label>Provedor</mat-label>
                <input disabled='true' matInput [(ngModel)]="data.provedor">
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <mat-form-field>
                <mat-label>Mês Base</mat-label>
                <mat-select disabled='false' [(ngModel)]="messelected" > 
                <mat-option *ngFor="let opcao of meslista" [value]="opcao">
                    {{opcao}}
                </mat-option>
                </mat-select>

             </mat-form-field>
        </div>

        <div class="col">
            <mat-form-field>
                <mat-label>Ano Base</mat-label>
                <mat-select disabled='false' [(ngModel)]="data.inicioAno" > 
                <mat-option *ngFor="let opcao of anolista" [value]="opcao">
                    {{opcao}}
                </mat-option>
                </mat-select>
             </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col">
                <mat-form-field>
                <mat-label>Total instalações</mat-label>
                <input disabled='true'  matInput [(ngModel)]="instalacoesTotal">
            </mat-form-field>
        </div>

        <div class="col">
            <mat-form-field>
            <mat-label>Baixadas</mat-label>
            <input disabled='true' matInput [(ngModel)]="instalacoesBaixadas">
        </mat-form-field>
        </div>

     </div>



    <div class="row">

        <div class="col">
            <mat-form-field>
            <mat-label>Inexistente</mat-label>
            <input disabled='true' matInput [(ngModel)]="instalacoesVerificar">
             </mat-form-field>
          </div>

        <div class="col">
                <mat-form-field>
                <mat-label>Entregues</mat-label>
                <input disabled='true' matInput [(ngModel)]="instalacoesEntregues">
            </mat-form-field>
        </div>



    </div>
         
    <div class="row">
        <div class="col">
            <mat-form-field>
            <mat-label>% Entregues vs instalações corretas</mat-label>
            <input disabled='true' matInput 
            [ngModel]="percentualCorretas/100 | percent: '1.1'" 
            (ngModelChange)="percentualCorretas=$event">
             </mat-form-field>
          </div>



        <div class="col">
                <mat-form-field>
                <mat-label>% Entregues vs total instalações</mat-label>
                <input disabled='true' matInput [ngModel]="percentualTotal/100 | percent: '1.1'" 
                (ngModelChange)="percentualTotal=$event">
            </mat-form-field>
        </div>

    </div>


    <div class="row">
        <div class="col">
                <mat-form-field>
                <mat-label>Time out</mat-label>
                <input disabled='true' matInput [(ngModel)]="instalacoesTimeout">
            </mat-form-field>
        </div>

        <div class="col">
            <mat-form-field>
            <mat-label>Ainda não disponíveis</mat-label>
            <input disabled='true' matInput [(ngModel)]="instalacoesIncompletos">
             </mat-form-field>
         </div>

    </div>

</div>

<div mat-dialog-actions>
    <div>
        <button [disabled]="this.CalcularDesabilitado || this.messelected==null " *ngIf="this.botaoCalcularVisivel" mat-raised-button (click)="onClickResumo()" color="primary">{{botaoAcao}}</button>
        <button *ngIf="this.botaoSairVisivel" mat-stroked-button (click)="onClickClose()" color="primary">Sair</button>
    </div>
    <div style="margin: 1rem;">
        <mat-spinner *ngIf="this.spinVisivel"  diameter="20" value="100" mode="indeterminate"></mat-spinner>
    </div>
  
    
</div>