<div style="margin: 2rem;">
  <div *ngIf="!origembuscainstalacao" class="botao-voltar">
     <button mat-stroked-button matTooltip="Voltar" matTooltipClass="tooltip-blue" (click)="IrPara(origem)" color="primary" ><mat-icon>arrow_back</mat-icon></button> &nbsp;
  </div>
  <div>
    <h1> Instalações </h1>
  </div>
</div>

<div style="margin: 2rem;">
<mat-form-field >
  <mat-label>instalacao</mat-label>
    <input disabled='false' matInput [(ngModel)]="instalacaoBusca">
    </mat-form-field>  
 <button style="margin: 1rem;" mat-stroked-button matTooltip="Dados da instalação" matTooltipClass="tooltip-blue"(click)="buscaInstalacao(instalacaoBusca)" color="primary">Buscar</button> &nbsp;
</div>




<div style="margin: 2rem;">
    
  <mat-form-field *ngIf="origemcontratos">
      <mat-label>Contrato</mat-label>
      <input disabled='true' matInput [(ngModel)]="nomecontrato">
  </mat-form-field>

  <mat-form-field *ngIf="origemcontratos" >
    <mat-label>Serviço</mat-label>
      <input disabled='true' matInput [(ngModel)]="nomeservicoselected">
  </mat-form-field>

  <mat-form-field  *ngIf="origemcontratos" >
    <mat-label>id do serviço</mat-label>
      <input disabled='true' matInput [(ngModel)]="servicoselected">
  </mat-form-field>

  <mat-form-field  *ngIf="origemcontratos"  appearance="legacy">
    <mat-label>Provedor</mat-label>
    <input  disabled='true' matInput [(ngModel)]="provedorselected">
   </mat-form-field>

   <mat-form-field *ngIf="origemcontratos" appearance="legacy">
    <mat-label>Cliente</mat-label>
    <input disabled='true' matInput [(ngModel)]="clienteselected">
   </mat-form-field>


   <mat-form-field *ngIf="origemjobs">
      <mat-label>Job</mat-label>
      <input disabled='true' matInput [(ngModel)]="jobselected">
    </mat-form-field>


   <!-- Esperando analise do filtro de instalacao
    <mat-form-field >
      <mat-label >Escolha uma instalação</mat-label>
      <mat-select  [(ngModel)]="instalacaoselected" (ngModelChange)="onContratoSelecionado()"> 
      <mat-option  *ngFor="let item of instalacoes | keyvalue" [value]="item.value.instalacao">
          {{item.value.instalacao}}                
      </mat-option>
    </mat-select>    
  </mat-form-field>-->

  
</div>
  
  <div class="mat-elevation-z8">
    <table [dataSource]="dataSource.data" mat-table class="full-width-table" matSort aria-label="Elements">
      
      <ng-container matColumnDef="ativo">
        <th mat-header-cell *matHeaderCellDef >Ativo</th>
        <td mat-cell *matCellDef="let row"><mat-icon> {{row.ativo == true ? "done" : "clear" }}</mat-icon></td>             
      </ng-container>

      <!-- Id Column -->
      <ng-container matColumnDef="instalacao">
        <th mat-header-cell *matHeaderCellDef >Instalação</th>
        <td mat-cell *matCellDef="let row">{{row.instalacao}}</td>
      </ng-container>
  
        <!-- Id Column -->
      <ng-container matColumnDef="cliente">
        <th mat-header-cell *matHeaderCellDef >Cliente</th>
        <td mat-cell *matCellDef="let row">{{row.cliente}}</td>
      </ng-container>

            <!-- Id Column -->
      <ng-container matColumnDef="nomeservico">
        <th mat-header-cell *matHeaderCellDef >Nome serviço</th>
        <td mat-cell *matCellDef="let row">{{row.nomeservico}}</td>
      </ng-container>
      <!-- Id Column -->
      <ng-container matColumnDef="clienteinstalacao">
        <th mat-header-cell *matHeaderCellDef >Cliente instalação</th>
        <td mat-cell *matCellDef="let row">{{row.clienteinstalacao}}</td>
      </ng-container>

 
      <!-- Name Column -->
      <ng-container matColumnDef="diaultimaleitura">
        <th mat-header-cell *matHeaderCellDef >Dia última emissão</th>
        <td mat-cell *matCellDef="let row">{{row.diaultimaleitura}}</td>
      </ng-container>
  
      <!-- Name Column -->
      <ng-container matColumnDef="inicioMes">
        <th mat-header-cell *matHeaderCellDef >Mês de início</th>
        <td mat-cell *matCellDef="let row">{{row.inicioMes}}</td>
      </ng-container>

        
      <!-- Name Column -->
      <ng-container matColumnDef="inicioAno">
        <th mat-header-cell *matHeaderCellDef >Ano de início</th>
        <td mat-cell *matCellDef="let row">{{row.inicioAno}}</td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="conexaoID">
        <th mat-header-cell *matHeaderCellDef >id da Conexão</th>
       <!--   <td mat-cell *matCellDef="let row" ><a [routerLink]="" (click)="openModalConexoes(row)">{{row.conexaoID}}</a></td> -->
        <td mat-cell *matCellDef="let row" >{{row.conexaoID}}</td>
      </ng-container>



       <!-- Name Column 
       <ng-container matColumnDef="arquivo">
        <th  mat-header-cell *matHeaderCellDef  >Pdf</th>
        <td  mat-cell *matCellDef="let row"><a href={{row.arquivo}} target="_blank"><mat-icon>picture_as_pdf</mat-icon></a></td>
       </ng-container>  -->

      <!-- Name Column  -->
      <ng-container matColumnDef="acoes">
        <th  mat-header-cell *matHeaderCellDef>Ações</th>
        <td mat-cell *matCellDef="let element" class="action-link">           
        <button mat-stroked-button matTooltip="Dados da instalação" matTooltipClass="tooltip-blue"(click)="openModalInstalacoes(element)" color="primary"><mat-icon>store_mall_directory</mat-icon></button> &nbsp;
        <button mat-stroked-button matTooltip="Dados da conexão" matTooltipClass="tooltip-blue"(click)="openModalConexoes(element)" color="primary"><mat-icon>lock</mat-icon></button> &nbsp;
        <button  mat-stroked-button matTooltip="Documentos disponíveis" matTooltipClass="tooltip-blue"(click)="openServicosDetalhes(element)" color="primary"><mat-icon>picture_as_pdf</mat-icon></button> &nbsp;        
      </ng-container>
         
  
  
     

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectedRow(row)"></tr>
    </table>
        <mat-paginator #paginator
        [length]="dataSource?.data.length"
        [pageIndex]="0"
        [pageSize]="20"
        [pageSizeOptions]="[20, 40, 80, 100]">
    </mat-paginator>


</div>
  
<div>
  &nbsp;
</div>


  