
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Total } from '../../models/total';
import { environment } from '../../../environments/environment';
import { FormControl, Validators, FormGroup} from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule} from '@angular/common';
import { DbserviceService } from '../../services/dbservice.service';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

@Component({
  selector: 'app-comentario',
  templateUrl: './comentario.component.html',
  styleUrls: ['./comentario.component.scss']
})

export class ComentarioComponent {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  }


  comentarioForm = new FormGroup({
    messelected: new FormControl('', [Validators.required]),
    anoselected: new FormControl('', [Validators.required])
  });

  
  url:string;
  food: string;
  instalacaolista: string[] = ['0400034096', '0400034312', '0400062090', '0400082490', '0400090735', '0400116823'];
  instalacaoselected: string = '0400034096';
  meslista: string[] = ['01','02','03','04','05','06','07','08','09','10', '11','12']
  messelected: string;
  anolista: string[] = ['2021','2020','2019'];
  anoselected: string = '2021'
  instalacoesTotal = 0;
  instalacoesBaixadas = 0;
  instalacoesEntregues = 0;
  instalacoesTimeout = 0;
  instalacoesVerificar = 0;
  instalacoesIncompletos = 0;
  percentualTotal = 0;
  percentualCorretas = 0; 
  botaoCalcularVisivel = true;
  botaoSairVisivel = true;
  CalcularDesabilitado = false;
  spinVisivel = false;
  botaoAcao = "Calcular";

  total = {} as Total;

  


  constructor(
    public dialogRef: MatDialogRef<ComentarioComponent>,
    private httpClient: HttpClient,
    private DbserviceService: DbserviceService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any) { }



  onClickGravar(): void {

    this.DbserviceService.update_DB('jobs', {comentarioverificacao: this.data.comentario}, this.data.id);
    this.openSnackBar('Comentário gravado com sucesso!!','Fechar');
    this.onClickClose()

  }


  onClickClose(): void{
     this.dialogRef.close();
  }

  openSnackBar(mensagem, saida) {
    this._snackBar.open(mensagem, saida, {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

}