import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { ClientesTableDataSource, TabelaItem } from './clientestable-datasource';
import { DbserviceService } from '../../../services/dbservice.service';
import { MatIconModule} from '@angular/material/icon';
import { FormControl } from "@angular/forms";
import { ElementRef} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-clientestable',
  templateUrl: './clientestable.component.html',
  styleUrls: ['./clientestable.component.scss'],
})
export class ClientesTableComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<TabelaItem>;
  dataSource: ClientesTableDataSource;
  displayedColumns = ['nome', 'cnpj','razaosocial', 'id', 'acao'];
  InstalacaoSelecionada: string;
  clientes:any;

  
  constructor(private DbserviceService: DbserviceService,
              private router: Router) { }

  ngOnInit() {
      
    this.DbserviceService.read_DB('clientes').subscribe(data => {
      this.clientes = data.map(e => {
        return {     
          id:          e.payload.doc.id,
          cnpj:        e.payload.doc.data()['cnpj'],
          nome:        e.payload.doc.data()['nome'],
          razaosocial: e.payload.doc.data()['razaosocial'],
          
          
        };
      })
      console.log('payload: ' + this.clientes);
      this.dataSource.data = this.clientes;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.table.dataSource = this.dataSource;
      
    });
    
    this.dataSource = new ClientesTableDataSource(); 
    
  }

  ngAfterViewInit() {
  }

  onInstalacaoSelecionada() {
    console.log(this.InstalacaoSelecionada);

    this.DbserviceService.read_DB('clientes').subscribe(data => {
      this.clientes = data.map(e => {
        return {     
          id:          e.payload.doc.id,
          cnpj:        e.payload.doc.data()['cnpj'],
          nome:        e.payload.doc.data()['nome'],
          razaosocial: e.payload.doc.data()['razaosocial'],
          
        };
      })
      console.log('payload: ' + this.clientes);
      this.dataSource.data = this.clientes;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.table.dataSource = this.dataSource;
    
    });
      
  this.dataSource = new ClientesTableDataSource(); 
  
  }

  openClientesForm(row) {
    console.log(row);
    this.router.navigate(['clientesForm', row ]);
  }

  
}
