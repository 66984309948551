<h1 mat-dialog-title>Tentativas</h1>
<div class="mat-elevation-z8"></div>
               
<table [dataSource]="local_data.tentativas" mat-table class="full-width-table" matSort aria-label="Elements">
    <!-- Id Column -->
    <ng-container matColumnDef="source">
      <th mat-header-cell *matHeaderCellDef >Origem</th>
      <td mat-cell *matCellDef="let row">{{row.source}}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef >Status</th>
      <td mat-cell *matCellDef="let row">{{row.status}}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="mensagem">
      <th mat-header-cell *matHeaderCellDef>Mensagem</th>
      <td mat-cell *matCellDef="let row">{{row.mensagem}}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="data">
      <th mat-header-cell *matHeaderCellDef>Data</th>
      <td mat-cell *matCellDef="let row">{{row.data == null ? "" : row.data.seconds*1000 | date:'dd/MM/yyyy | hh:mm a' }}</td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns; sticky: true"></tr>
  </table>
    


<div mat-dialog-content class="col"></div>
    

