<div class="grid-container">
  <h1 class="mat-h1">Seja benvindo!</h1>
  <mat-grid-list cols="2" rowHeight="350px">
    <mat-grid-tile *ngFor="let card of cards | async ; let i = index" [colspan]="card.cols" [rowspan]="card.rows">
      <mat-card class="dashboard-card">
        <mat-card-header>
          <mat-card-title>
            {{card.title}}
            <button mat-icon-button class="more-button" [matMenuTriggerFor]="menu" aria-label="Toggle menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu" xPosition="before">
              <button mat-menu-item>Expand</button>
              <button mat-menu-item>Remove</button>
            </mat-menu>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="dashboard-card-content">
          
          <div>

            <div *ngIf="i==0">
              <div>

                <mat-card class="example-card">
                  <mat-card-header>
                    <div mat-card-avatar class="example-header-image"></div>
                    <mat-card-title>Dica do dia</mat-card-title>
                    <mat-card-subtitle>Interessante</mat-card-subtitle>
                  </mat-card-header>
                  <mat-card-content>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla in varius nibh,
                       a pulvinar mauris. Donec ullamcorper arcu eget nisl fringilla condimentum. 
                       Curabitur sit amet purus molestie, pulvinar risus in, molestie mi. 
                    </p>
                  </mat-card-content>
                  <mat-card-actions>
                    <button mat-button>LIKE</button>
                    <button mat-button>SHARE</button>
                  </mat-card-actions>
                </mat-card>



              </div>

            </div>

            
            <div *ngIf="i==1">
              <div>1</div>
            </div>
           
           
            <div *ngIf="i==2">
              <div>
       

                <mat-card class="example-card">
                  <mat-card-header>                   
                    <mat-card-title>Ação Engenharia</mat-card-title>
                    <mat-card-subtitle>CNPJ: 06.942.456/0001-04</mat-card-subtitle>
                  </mat-card-header>
                  <mat-card-content>

                  </mat-card-content>
   
                </mat-card>



              </div>
            </div>

            <div *ngIf="i==3">
              <div>3</div>
            </div>

            

          </div>



        </mat-card-content>
      </mat-card>
    </mat-grid-tile>
  </mat-grid-list>
</div>
