import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { promise } from 'selenium-webdriver';
import { firestore } from 'firebase/app';


import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class DbserviceService {

  statusEntrega:any;

  constructor(
    private firestore: AngularFirestore,
    private _snackBar: MatSnackBar,
  ) { }

  //create_NewInvoice(record) {
  //  return this.firestore.collection('light').add(record);
  //}

  async get_Data(dblevel, doc)    { 
    var docRef = this.firestore.collection(dblevel).doc(doc);
    console.log(dblevel,doc)

    await docRef.get()
    .toPromise()
    .then(async function(doc) {
      if (doc.exists) {
          console.log("Document data:", doc.data());
          return await doc.data();
      } else {
          // doc.data() will be undefined in this case
          console.log("No such document!!");
      }
    }).catch(function(error) {
      console.log("Error getting document:", error);
    });   
  }

  read_DB(dblevel) { 
    return this.firestore.collection(dblevel).snapshotChanges();
  }

  update_DB(dblevel, payload, id) { 
    return new Promise<any>((resolve, reject) =>{
      console.log('payload updateDB',payload);
      console.log('id',id);
      
      if (id != undefined){
        this.firestore
            //.collection(dblevel)
            .doc(dblevel+'/'+id)
            .set(payload, {merge: true})
            .then(res => {}, err => reject(err));
      }
      else {
          this.firestore
          //.collection(dblevel)
          .collection(dblevel)
          .add(payload)
          .then(res => { console.log('Resposta update_DB',res.id)}, err => reject(err));
      }

    });
  }


  

  delete_DB(dblevel,id) { 
    return new Promise<any>((resolve, reject) =>{      
      console.log('id',id);
      
        this.firestore
            .collection(dblevel)
            .doc(id)
            .delete()
            .then(res => {}, err => reject(err));

    });
  }

  jobProcessamento(dblevel, payload, id) { 
    

    
    return new Promise<any>((resolve, reject) =>{
      console.log('payload updateDB',payload);
      console.log('id',id);

      payload.data = firestore.FieldValue.serverTimestamp();
    
      if (id != undefined){
        this.firestore
            //.collection(dblevel)
            .doc(dblevel+'/'+id)
            .set(payload, {merge: true})
            .then(res => {}, err => reject(err));
      }
      else {
   


          this.firestore
          //.collection(dblevel)
          
          .collection(dblevel)
          .add(payload)
          .then(res => { console.log('Resposta update_DB',res.id)

          this.openSnackBar('Lote de processamento criado: ' + res.id,'Fechar');
        
          return id}, err => reject(err));
      }

    });
  }

  read_Invoices(dblevel) { 
    return this.firestore.collection(dblevel).snapshotChanges();
  }

  read_Doc(dblevel,doc) { 
    return this.firestore.collection(dblevel).doc(doc).snapshotChanges();
  }

  add_Contratos(dblevel, doc, data) { 
    return this.firestore.collection(dblevel).doc(doc).set(data);
  }

  read_Contratos(dblevel) { 
    return this.firestore.collection(dblevel)
    .snapshotChanges();
  }


  

  read_Contratos_Where(dblevel,campo, condicao, comparacao) { 
    return this.firestore.collection(dblevel, ref =>
           ref.where(campo, condicao, comparacao)).snapshotChanges();
  }

  read_Contratos_Where_Order(dblevel,campo, condicao, comparacao, ordem) { 
    return this.firestore.collection(dblevel, ref =>
           ref.where(campo, condicao, comparacao).orderBy(ordem)).snapshotChanges();
  }


  read_Contratos_2Where(dblevel,campo, condicao, comparacao, campo2, condicao2, comparacao2) { 
    return this.firestore.collection(dblevel, ref =>
           ref.where(campo, condicao, comparacao)
              .where(campo2, condicao2, comparacao2)).snapshotChanges();
  }

  read_Contratos_3Where(dblevel,campo, condicao, comparacao,
                                campo2, condicao2, comparacao2,
                                campo3, condicao3, comparacao3) { 
                                  
    return this.firestore.collection(dblevel, ref =>
           ref.where(campo, condicao, comparacao)
              .where(campo2, condicao2, comparacao2)
              .where(campo3, condicao3, comparacao3)).snapshotChanges();
  }

  delete_Contratos(dblevel, doc) { 
    return this.firestore.collection(dblevel).doc(doc).delete();
  }

  read_Jobs(dblevel) { 
    return this.firestore.collection(dblevel).snapshotChanges();
  }

  read_Jobs_whereStatus(dblevel,status,baseMes,baseAno) { 
    return this.firestore.collection(dblevel, ref =>
      ref
      .where('status', '==' , status)
      .where('baseMes', '==' , baseMes)
      .where('baseAno', '==' , baseAno)
      .orderBy('nomeservico', 'asc')
      .orderBy('termino', 'desc'))
      .snapshotChanges();
      //.stateChanges()
      //.valueChanges()
      
  }


  read_OcrJobs(dblevel,limit) { 
    return this.firestore.collection(dblevel, ref =>
      ref
      .orderBy('data', 'desc')
      .limit(limit))  
      .snapshotChanges();
      //.stateChanges()
      //.valueChanges()
      
  }



  
  read_Jobs_whereOCRStatus(dblevel,statusEntregaOCR,baseMes,baseAno) { 

   // if (statusEntregaOCR == 'Sim')
  //    this.statusEntrega = true
   // else
   //   this.statusEntrega = false;




    if (baseMes.length == 0) 
      baseMes = ['00'];

    console.log('whereOCR',dblevel,statusEntregaOCR,baseMes,baseAno)

    return this.firestore.collection(dblevel, ref =>
      ref
      //.where('status', '==' , 'finalizado')
      //.where('ocrocrFeito', '==' , true)
      .where('ocrStatus', '==' , statusEntregaOCR)
      //.where('baseMes', '==' , baseMes)
      .where('baseMes', 'in',baseMes)      
      .where('baseAno', '==' , baseAno)
      .orderBy('nomeservico', 'asc')
      .orderBy('termino', 'desc'))
      .snapshotChanges();
      //.stateChanges()
      //.valueChanges()
      
  }





  read_Servicedetails(dblevel) { 
    return this.firestore.collection(dblevel).snapshotChanges();
  }

  AddNewJob(payload) { 
    return new Promise<any>((resolve, reject) =>{
      this.firestore
          .collection("jobs")
          .add(payload)
          .then(res => {}, err => reject(err));
    });
  }

  UpdateServico(id, atualizacao) { 
    return new Promise<any>((resolve, reject) =>{
      this.firestore
          .doc(id)
          .update(atualizacao)
          .then(res => {}, err => reject(err));
    });
  }
    

  atualizarJob(jobid, source, status) { 
    return this.firestore.collection('jobs').doc(jobid).update({
      source: source,
      status:status,
      feito: false,
      inicio: null,
      termino: null,
      mensagem: ''

    }); 
 
  }

 
  openSnackBar(mensagem, saida) {
    this._snackBar.open(mensagem, saida, {
      //duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  alteraStatusJob(jobid,status) { 
    return this.firestore.collection('jobs').doc(jobid).update({      
      status: status,           

    }); 
 
  }

  //update_Invoice(recordID,record){
  //  this.firestore.doc('/' + recordID).update(record);
  //}

  //delete_Invoice(record_id) {
  //  this.firestore.doc('/' + record_id).delete();
  //}
}


